import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../../framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";
import { apiCall } from "../../../../components/src/APICall";

export const configJSON = require("../config.js");

interface ShippingAddress {
  id: number;
  country: StringNull;
  latitude: number;
  longitude: number;
  address_name: string;
  addressble_id: number;
  addressble_type: string;
  address_type: StringNull;
  created_at: string;
  updated_at: string;
  first_name: StringNull;
  last_name: StringNull;
  number: StringNull;
  street: string;
  zipcode: string;
  area: string;
  block: string;
  city: StringNull;
  house_or_building_number: string;
  floor: StringNull;
  apartment_number: StringNull;
  name: string;
  contact_number: string;
  is_default: boolean;
  country_code: string;
  phone_number: string;
  country_code_name: StringNull;
}

type StringNull = string | null

interface OrderRequestAttributes {
  product_name: string;
  product_desc: StringNull;
  status: string;
  gender: string;
  size: string;
  color: string;
  quantity: number;
  product_quantity: number;
  accept_through: StringNull;
  accept_time: StringNull;
  primary_display_image: string;
  primary_image: string;
  price_per_unit: string;
  shipping_cost: string;
  total_amount: string;
  sku: StringNull;
  product_sourcing_request_id: StringNull;
  stylist: string;
  buyer: string;
  estimated_arrival_time: string;
  payment_method: StringNull;
  shipping_address: ShippingAddress[];
}

interface OrderRequestData {
  id: string;
  type: string;
  attributes: OrderRequestAttributes;
}

// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isStylistBuyer: boolean;
  currencySign: string;
  activeCartView: OrderRequestData;
  isSuccessModal: boolean;
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BuyerStylishCartPaymentDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderDetailsApiCallId: string = "";
  checkoutProductAPICallId: string ="";
  getRetrieveOrderChargeAPICallId: string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isError: false,
      errMsg: "",
      isStylistBuyer: false,
      isLoading: true,
      isSuccessModal: false,
      currencySign: '$',
      activeCartView: {
        id: "",
        type: "order_request",
        attributes: {
          product_name: "",
          product_desc: null,
          status: "in_cart",
          gender: "",
          size: "",
          color: "",
          quantity: 1,
          product_quantity: 0,
          accept_through: null,
          accept_time: null,
          primary_display_image: "",
          primary_image: "",
          price_per_unit: "0.0",
          shipping_cost: "0.0",
          total_amount: "0.0",
          sku: null,
          product_sourcing_request_id: null,
          stylist: "",
          buyer: "",
          estimated_arrival_time: "",
          payment_method: null,
          shipping_address: [],
        },
      } as OrderRequestData,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson || responseJson.error || responseJson.errors || responseJson.message) {
      return this.handleErrorResponse(responseJson);
    }
  
    switch (apiRequestCallId) {
      case this.getOrderDetailsApiCallId:
        this.handleGetOrderResp(responseJson);
        break;
  
      case this.checkoutProductAPICallId:
        this.handlePlaceOrderResp(responseJson);
        break;
  
      case this.getRetrieveOrderChargeAPICallId:
        this.handlePaymentRetrieveResp();
        break;
  
      default:
        this.setState({ isLoading: false})
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const isStylistBuyer = await getStorageData('exploreBuyer', true);
    if(isStylistBuyer) this.setState({isStylistBuyer: isStylistBuyer})
    await this.getOrderDetailsApi()
    const getTapId = await getStorageData("paymentTapId")
    getTapId && await this.retrieveChargeOrderApi(getTapId);
    // Customizable Area End
  }

  // Customizable Area Start

  retrieveChargeOrderApi = async (getTapId:string) => {
    const authToken = await getStorageData("auth-token");
    this.getRetrieveOrderChargeAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.retrieveChargeEndPoint}?charge_id=${getTapId}`,
      token: authToken,
    })
  }

  getOrderDetailsApi = async () => {
    const token = await getStorageData("auth-token");  
    const orderId = await getStorageData("stylish_buyer_cart_id");  
    this.getOrderDetailsApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getCartEndPoint}?id=${orderId}`,
      token: token,
    })
  }

  placeOrder = async () =>{
    const orderId = await getStorageData("stylish_buyer_cart_id");
    const token = await getStorageData("auth-token");
    const basePath = `${document.location.origin}`;
    const orderData = {
      "data": {
        "order_request_id": orderId ,
        "redirect_url": `${basePath}/BuyerStylishCartPaymentDetail`
      }
    }
    this.setState({isLoading: true})
    this.checkoutProductAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.checkoutChatCartEndPoint}?`,
      body: orderData,
      isJsonStringify: true,
      token: token,
    })
  }

  handleGetOrderResp = async (responseJson: { cart_item: {data: OrderRequestData}}) => {
    if (responseJson && responseJson.cart_item) {
      const userDetails = await getStorageData('userRole', true)
      if(userDetails?.currencyLogo){
        this.setState({
          currencySign: userDetails.currencyLogo
        })
      }
      this.setState({ activeCartView: responseJson.cart_item.data, isLoading: false });
      setStorageData("stylish_buyer_cart_id", responseJson.cart_item.data.id);
    }
  }

  handlePaymentRetrieveResp = async () => {
    const getTapId = await getStorageData("paymentTapId")
    if (window.location.href.includes(getTapId)) {
      removeStorageData("paymentTapId")
      this.setState({ isSuccessModal: true, isLoading: false })
    } 
  }

  handlePlaceOrderResp = (responseJson:{id: string, transaction: {url: string}}) =>{
    if( responseJson && responseJson?.transaction?.url){
      this.setState({ isLoading: false})
      setStorageData("paymentTapId", responseJson.id)
      window.location.href = responseJson.transaction.url
    }
  }

  handleErrorResponse = (responseJson?: { message?: string; error?: string; errors?: Array<Record<string, string>> }) => {
    let errMsg = 'Something went wrong';
    if (responseJson) {
      if (responseJson.error) {
        errMsg = responseJson.error;
      } else if (responseJson.message) {
        errMsg = responseJson.message;
      } else if (responseJson.errors?.length) {
        errMsg = responseJson.errors.map(error => Object.values(error)[0]).join(", ");
      }
    }
    this.setState({ isError: true, errMsg, isSuccessModal: false, isLoading: false });
  };

  navigateToShippingAddressPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ShippingAddressDetail");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToChatPage = async() => {
    const chatId = await getStorageData("hiredStylistId")
    await removeStorageData("paymentTapId")
    await removeStorageData("stylish_buyer_cart_id")
    chatId && this.props.navigation.navigate("HiredStylistProfile", {'navigationBarTitleText': `chat${chatId}`})
  }

  onCloseSnack = () => {
    this.setState({
      isError: false,
      errMsg: '',
    })
  }

  transCartPay = (payCartKey: string) => {
    return i18n.t(payCartKey, { ns: "shoppingCart" })
  }

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
