import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { location } from "../../addressmanagement/src/assets"
import CheckoutSteps from "../../productdescription3/src/CheckoutSteps.web";
import { createTheme, styled, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import {Noimageavailable} from "../../productdescription3/src/assets"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { GoogleMap, Marker } from "@react-google-maps/api";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import i18n from "../../../components/src/i18next/i18n";
import { getLocationDetails } from "../../../components/src/Googlelogin/googleMap.web";
// Customizable Area End

import Shippingaddressvalidation2Controller, {
  Props,
  CountrySuccess
} from "./Shippingaddressvalidation2Controller";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import CountrySelect from "../../../components/src/CountryDropDown.web";
import { CustomTypography } from "../../addressmanagement/src/EditAddress.web";

export default class AddShippingaddressvalidation2 extends Shippingaddressvalidation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleToggle = this.handleToggle.bind(this);
    this.handleAddInputChange = this.handleAddInputChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderLocationButton() {
    return (
      <div
        className="address_child"
        data-test-id="currentLocation"
        style={{
          cursor: 'pointer',
          backgroundColor: "#CCBEB1",
          padding: "19px 35px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "22px",
          borderRadius: "2px",
          width: 184,
          marginLeft: "10px",
          flexDirection: i18n.dir() === "ltr" ? "row": "row-reverse"
        }}
        onClick={this.handleUseCurrentLocationShipping}
      >
        <img src={location} alt="location icon" />
        <CustomTypography>{this.transShippingAddress("Use my current location")}</CustomTypography>
      </div>
    );
  }
  handleUseCurrentLocationShipping = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const currentLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setState({
            currentLocation: currentLatLng,
            selectedLocation: currentLatLng, 
            mapVisible: true
          })
          const { city, area, houseNumber, blockNumber, postalCode, formattedAddress} = await getLocationDetails(currentLatLng.lat, currentLatLng.lng)
          this.setState({
            formData: {
              ...this.state.formData,
              latitude: currentLatLng.lat,
              block: blockNumber,
              house_or_building_number: houseNumber,
              zipcode: postalCode,
              street: formattedAddress,
              longitude: currentLatLng.lng,
              city,
              area,
            },
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        },
      );
    } 
  };
  renderSearchInput() {
    return (
      this.state.mapsLoaded && (
        <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
          <input
            id="autocomplete1"
            type="text"
            placeholder="Search for a place"
            style={{
              padding: "10px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "white",
              display: this.state.mapVisible ? "block" : "none",
              borderRadius: "4px",
              width: "254px",
            }}
          />
        </div>
      )
    );
  }

  renderGoogleMap() {
    return (
      this.state.mapsLoaded &&
      this.state.mapVisible &&
      this.state.currentLocation && (
        <GoogleMap
          id="addMap"
          data-test-id="addMapTest"
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocation}
          onClick={(event: google.maps.MapMouseEvent) => this.handleMapClick(event, "addMap")}
        >
          {this.state.selectedLocation && (
            <Marker position={this.state.selectedLocation} /> // Place marker on selected location
          )}
        </GoogleMap>
      )
    );
  }
  
  renderOrderSummary() {
    const { orderSummary } = this.state;
    if (!orderSummary) return null;

    const { order_items, sub_total, total } = orderSummary.data.attributes;

    return (
      <div>
        {order_items && order_items.map((item) => (
          <div>
          <div key={item.id} style={{ ...webStyle.shippingOrder, display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
            <img src={item.attributes.catalogue_variant_front_image === "" ? Noimageavailable : item.attributes.catalogue_variant_front_image} 
            style={{ width: '63px', borderRadius: '4px', height: '63px', }} />
            <div style={{ ...webStyle.orderDetail, flex: '1', margin: i18n.dir()==='ltr'?'0px 0px 0px 10px': '0px 10px 0px 0px', }}>
              <p>{item.attributes.catalogue_name} <span style={{ color: '#94A3B8' }}>x {item.attributes.quantity}</span></p>
              <p style={{ marginTop: '-18px' }}>{this.transShippingCart("Color")} : <span style={{ color: '#94A3B8' }}>{item.attributes.catalogue_variant_color}</span></p>
            </div>
            <p style={{ fontSize: '14px',color: '#94A3B8' , marginLeft: '10px', }}>{this.state.currencySign} {item.attributes.total_price}</p>
          </div>
          <hr style={{ color: 'rgb(242, 240, 240)' }} />
          </div>
        ))}
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1',fontSize: '18px', marginLeft: '10px',  }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>{this.transShippingCart("Subtotal")} <span style={{ color: '#94A3B8' }}>({order_items.length} {this.transShippingCart("items")})</span></p>
              <p style={{ fontSize: '18px' }}>{this.state.currencySign} {parseFloat(sub_total).toFixed(2)}</p>
            </div>
            <div style={{ display: 'flex', fontSize: '18px', justifyContent: 'space-between', }}>
              <p style={{ marginTop: '-5px' }}>{this.transShippingCart("Savings")}</p>
              <p style={{ marginTop: '-5px' }}>-{this.state.currencySign} 0.00</p>
            </div>
          </div>
        </div>
        {Number(this.state.orderSummary?.data?.attributes?.loyalty_points_discount) > 0 && <Box style={webStyle.spaceBetweenAdd} my="0.75rem">
                                    <Typography style={{...webStyle.savedMethodDataAdd,color:'#01835c'}}>
                                      {this.transShippingCart("Loyalty Points")}
                                    </Typography>
                                    <Typography style={{...webStyle.savedMethodDataAdd,color:'#01835c'}} dir="ltr">-{this.state.currencySign} {this.state.orderSummary?.data?.attributes?.loyalty_points_discount}</Typography>
                                  </Box>}
        <hr style={{ color: 'rgb(242, 240, 240)' }} />
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1', marginLeft: '10px', fontSize: '18px' }}>
            <div style={{justifyContent: 'space-between', display: 'flex',  fontSize: '18px' }}>
              <p>{this.transShippingCart("Total")}</p>
              <p>{this.state.currencySign} {parseFloat(total).toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div style={{ ...webStyle.placeOrderbutton, ...webStyle.saveButton }} onClick={() => this.placeOrderNavigation()}>{this.transShippingCart("Place Order")}</div>
      </div>
    );
  }

  // Customizable Area End

  render() {
    const { formData,errors } = this.state;

    const GreenSwitch = withStyles({
      switchBase: {
        color: "#fff",
        "&$checked": {
          transform: "translateX(22px)",
          color: "#fff",
          "& + $track": {
            backgroundColor: "#CCBEB1",
            opacity: 1,
            border: "none",
            height: 17,
          }
        },
        "&$focusVisible $thumb": {
          color: "#4cd964",
          border: "6px solid #fff",
        }
      },
      thumb: {
        width: 19,
        height: 21
      },
      track: {
        borderRadius: 16 / 2,
        opacity: 1,
        border: "1px solid #E5E5EA",
        backgroundColor: "#E5E5EA",
        transition: "background-color 0.3s"
      },
      checked: {},
      focusVisible: {}
    })(Switch);
    return (
      // Customizable Area Start
      <>
      {this.state.stylishBuyer ? <StylishBuyerHeader navigation={this.props.navigation} />: <Header navigation={this.props.navigation} />}
        <Box style={{ backgroundColor: "rgb(248, 248, 248)", color: "#375280", fontFamily: "Lato" }}>
          <CheckoutSteps activeBread="address" transStepLang={this.transShippingCart}/>

        </Box>
        <div style={{ display: "flex", padding: "40px",backgroundColor: "#F8F8F8" }}>
          <Grid
            style={{  marginBottom: "58px" }}
            container
            dir={i18n.dir()}
          >

            <Grid item xs={12} md={8} style={{ paddingInline: 20 }}>

              <div style={{ backgroundColor: "#F8F8F8" }}>
                <div>
                  <div style={webStyle.addAddressContainer as React.CSSProperties}>

                    <div
                      className="address_child"
                      style={{
                        fontSize: "20px",
                        color: "#375280",
                        marginBottom: "22px",
                        marginLeft: "10px",
                        fontFamily: 'Lato , sans-serif',
                      }}
                    >
                      {this.transShippingAddress("Add Address")}
                    </div>
               
                    <div style={{ position: "relative" }}>
      {this.renderLocationButton()}
      {this.renderSearchInput()}
      {this.renderGoogleMap()}
    </div>
                    <Grid
                      container
                      spacing={2}
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",

                          }}

                        >
                          {this.transShippingAddress("Full Name")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          name="name"
                          variant="outlined"
                          value={formData.name}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.name && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.name}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6} id="grid-item-phone">
                        <MainGridContainer container id="grid-container-phone">
                          <Grid item style={{ width: "100%" }}>
                            <Box>
                              <Typography
                                data-test-id="countryId"
                                className={"inputHeading"}
                                id="country-phone-id"
                              >
                                {this.transShippingAddress("Phone Number")}
                                <span style={{ color: '#375280' }}>*</span>
                              </Typography>
                              <Box
                                className={"phoneInput"}
                                id="box-phone"
                                style={{ width: "100%" }}
                              >
                                <CustomDropDownBox>
                                <CountrySelect
                                  id="phoneCode"
                                  name="phoneCode"
                                  value={this.state.selectedCountry?.country_code || "KW"}
                                  placeholder="Select Phone Code"
                                  onChange={this.handleCountrySelect}
                                  countryCode={this.state.countrySuccess}
                                  isBorder
                                />
                                </CustomDropDownBox>
                                <TextField
                                  data-test-id="phoneNumberTextFiled"
                                  variant="outlined"
                                  className="inputFeild2"
                                  style={{ width: "100%" }}
                                  placeholder={this.transShippingAddress("Enter Contact Number")}
                                  inputProps={{ maxLength: 12 }}
                                  name="phone_number"
                                  value={formData.phone_number}
                                  onChange={this.handlePhoneNumberChange}
                                  InputProps={{
                                    style: {
                                      gap: "4px",
                                      border: "0px",
                                      borderRadius: "8px",
                                      width: "100%"
                                    }
                                  }}
                                />

                              </Box>
                              {errors.phone_number && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.phone_number}</span>}
                            </Box>
                          </Grid>
                        </MainGridContainer>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.transShippingAddress("Street Name")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="street"
                          value={formData.street}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.street && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.street}</span>}

                      </Grid>
                      <Grid item xs={12} sm={6} id="grid-item-house-building">
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            fontFamily: "Lato , sans-serif",
                            color: "#375280",
                          }}
                          id="p-house-building"
                        >
                          {this.transShippingAddress("House / Building Number")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          id="input-house-building"
                          variant="outlined"
                          name="house_or_building_number"
                          value={formData.house_or_building_number}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.house_or_building_number && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.house_or_building_number}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6} id="gird-item-block">
                        <p
                          id="p-block"
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                            fontWeight: 700,
                          }}
                        >
                          {this.transShippingAddress("Block")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          id="input-block"
                          fullWidth
                          variant="outlined"
                          name="block"
                          value={formData.block}
                          onChange={this.handleAddInputChange}
                          placeholder={this.transShippingAddress("Enter Block")}
                        />
                        {errors.block && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.block}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6} id="grid-item-area">
                        <p
                          style={{
                            fontSize: "16px",
                            fontFamily: "Lato , sans-serif",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                          }}
                          id="p-area"
                        >
                          {this.transShippingAddress("Area")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          id="input-area"
                          fullWidth
                          variant="outlined"
                          name="area"
                          value={formData.area}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.area && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.area}</span>}
                      </Grid>
                      <Grid item xs={12} id="grid-item-city" sm={6}>
                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                          id="p-city"
                        >
                          {this.transShippingAddress("City")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          id="input-city"
                          data-test-id="cityTestId"
                          fullWidth
                          variant="outlined"
                          name="city"
                          value={formData.city}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.city && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.city}</span>}

                      </Grid>
                      <Grid item id="grid-item-zip-code" xs={12} sm={6}>
                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                          id="p-zip-code"
                        >
                          {this.transShippingAddress("Zip Code")}
                        </p>
                        <InputField
                          data-test-id="memberId"
                          id="input-zip-code"
                          fullWidth
                          variant="outlined"
                          name="zipcode"
                          value={formData.zipcode}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.zipcode && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.zipcode}</span>}

                      </Grid>
                      <Grid item xs={12} id="grid-item-address-name" sm={6}>
                        <p
                          id="p-address-name" 
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 700,
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          {this.transShippingAddress("Address Name")}
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          id="input-address-name" 
                          fullWidth
                          variant="outlined"
                          name="address_name"
                          value={formData.address_name}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.address_name && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.address_name}</span>}

                      </Grid>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#375280",
                        fontSize: "16px",
                        fontWeight: 500
                      }}
                    >
                      <GreenSwitch
                        checked={this.state.isCheccked}
                        onChange={this.handleAddToggle}
                        inputProps={{ "aria-label": "toggle switch" }}
                        id="default-switch" 
                      />
                      {/* <p>Toggle is {this.state.isChecked ? 'ON' : 'OFF'}</p> */}
                      <p id="p-default-switch" >{this.transShippingAddress("Make it default delivery address")}</p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        paddingInline: 10,
                        alignItems: "center",
                        gap: 22,
                      }}
                    >
                      <div style={webStyle.button12} onClick={this.getNavigationAddAddress}>{this.transShippingAddress("Cancel")}</div>
                      <div
                        style={{ ...webStyle.button12, ...webStyle.saveButton }}
                        onClick={this.handleSubmit}
                        data-test-id="handleSubmit"
                      >
                        {this.transShippingAddress("Save")}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }}>

              <div style={{ margin: 'auto', paddingInline: 20 }}>
                <div>

                  <div style={webStyle.addAddressContainer1 as React.CSSProperties} >
                    <div style={{ marginBottom: "14px", cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" }}>
                      {this.transShippingCart("Order Summary")}
                    </div>
                    <hr style={{ color: 'rgb(242, 240, 240)' }} />

                    {this.renderOrderSummary()}

                  </div>

                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer navigation={this.props.navigation} />
        <Snackbar
          data-test-id="alertTestId"
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  addAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  placeOrderbutton: {
    padding: "10px 16px 10px 16px",
    height: "64px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: '5px'
  },
  orderDetail: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato , sans-serif',
  },
  shippingOrder: { marginBottom: "14px", cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" },
  addAddressContainer1: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '24px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '28.8px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  dropdown: {
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 0px #00000014',
    backgroundColor: 'white',
    zIndex: 1,
    left: 0,
    width: '120px',
    top: '100%',
    position: 'absolute',
  },
  dropdownP: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingBottom: '10px',
    borderBottom: '3px solid #D5D5D5',
  },
  dropdownP1: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  button12: {
    padding: "10px 16px 10px 16px",
    width: "50%",
    height: "64px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: '5px'
  },
  button123: {
    padding: "10px 16px 10px 16px",
    height: "64px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: '5px'
  },
  saveButton: {
    color: "white",
    backgroundColor: "#CCBEB1"
  },
  savedMethodDataAdd: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  spaceBetweenAdd: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
const InputField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "#375280 !important"
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
      borderWidth: "1px",
    }
  },
  "& .MuiInputBase-root": {
    fontSize: "16px",
    borderRadius: "8px",
    margin: "auto",
    height: "56px",
    backgroundColor: "#F0F0F0",
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-input": {
    color: "#1E293B",
    padding: "10px 16px 10px 16px",
    "&::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      "@media only screen and (max-width: 414px)": {
        fontSize: "14px"
      }
    },
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    }
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  }
});
const MainGridContainer = styled(Grid)({
  justifyContent: "center",
  display: "flex",

  "& .mainCotainer": {
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "26px",
    fontFamily: "Avenir,sans-serif",
    alignSelf: "flex-start",
    textOverflow: "ellipsis",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    marginBottom: "40px",
  },

  "& .inputHeading": {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
    marginTop: "20px",
    fontStyle: "normal",
    marginBottom: "10px",
    color: "#375280",
    fontFamily: "Lato, sans-serif",
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    width: "100%px",
    height: "56px",
    gap: "15px",
    display: "flex",
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    fontFamily: "Lato, sans-serif",
    cursor: "pointer",
    margin: "10px",
    textOverflow: "ellipsis",
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
   
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important"
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #F0F0F0 !important"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
   
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
   
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiTextField-root": {
        "&:hover": {
          border: "1px solid #F0F0F0 !important"
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        border: "1px solid #F0F0F0 !important"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
     
      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"
    }
  }
});
const CustomDropDownBox = styled(Box)({
  width: "180px",
  cursor: "pointer",
  position: "relative",
});
const DropDownHeaderMenu = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  gap: "15px",
  background: "#F0F0F0",
  fontSize: "18px",
  color: "#375280",
  height: "36px",
  padding: "10px",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const DropDownOptionsList = styled(Box)({
  backgroundColor: "#fff",
  color: "#375280",
  overflowX: "hidden",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  height: "60vh",
  zIndex: 1,
  position: "absolute",
  border: "1px solid #ccc",
  left: 0,
  width: "100%",
});
const OptionsValueList = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
