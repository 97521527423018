import React, { Component } from "react";
import { threeDot } from "../../../addressmanagement/src/assets";
import { setStorageData } from "framework/src/Utilities";
import { Box, Popover, styled } from "@material-ui/core";
import { checkCondition } from "../../../../components/src/Seller/logOut";

interface Props {
  openDropdownId: string | null;
  transPay: (transKey: string) => string;
  addressId: string;
  isDefault: boolean;
  toggleDropdown1: (id: string) => void;
  handleOpenDeleteDialog: (id: string) => void;
  getEditNavigationToPath: (id: string, path: string) => void;
  handleMakeDefault: (id: string) => void;
}

interface S {
  anchorEl: HTMLElement | null;
}

class AddressDropDown extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderDropdown = (addressID: string, isDefault: boolean) => {
    const { anchorEl } = this.state;
    const {
      openDropdownId,
      transPay,
      handleMakeDefault,
      handleOpenDeleteDialog,
      getEditNavigationToPath,
    } = this.props;
    const isOpen = Boolean(anchorEl);
    if (openDropdownId === addressID) {
      setStorageData("addressID", addressID);
      return (
        <Popover
          anchorEl={anchorEl}
          open={isOpen}
          onClose={this.handleClose}
          data-test-id="Popover"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MainDropDownContainer>
            {!isDefault && (
              <p
                className="is-default-css"
                data-test-id="handleMakeDefault"
                onClick={() => handleMakeDefault(addressID)}
              >
                {transPay("Make Default")}
              </p>
            )}
            <p
              className={checkCondition(isDefault, "dropdown-default-css", "is-default-css") as string}
              data-test-id="getNavigationEditAddress"
              onClick={() =>
                getEditNavigationToPath(
                  addressID,
                  "EditShippingAddressManagement"
                )
              }
            >
              {transPay("Edit Address")}
            </p>
            {!isDefault && (
              <p
                className="delete-css"
                data-test-id="handleOpenDeleteDialog"
                onClick={() => handleOpenDeleteDialog(addressID)}
              >
                {transPay("Delete")}
              </p>
            )}
          </MainDropDownContainer>
        </Popover>
      );
    }
  };

  handleClick = (
    event: React.MouseEvent<HTMLImageElement>,
    addressID: string
  ) => {
    this.setState({ anchorEl: event.currentTarget });
    this.props.toggleDropdown1(addressID);
  };

  render() {
    const { addressId, isDefault } = this.props;
    return (
      <StyleImgPopOver>
        <img
          className="img-css"
          src={threeDot}
          data-test-id="clickbyyyy"
          onClick={(e) => this.handleClick(e, addressId)}
        />
        {this.renderDropdown(addressId, isDefault)}
      </StyleImgPopOver>
    );
  }
}

export default AddressDropDown;

const StyleImgPopOver = styled(Box)({
  "& .img-css": {
    position: "relative",
    cursor: "pointer",
  },
});

const MainDropDownContainer = styled(Box)({
  zIndex: 1,
  left: 0,
  backgroundColor: "white",
  width: "120px",
  boxShadow: "0px 2px 8px 0px #00000014",
  top: "100%",
  borderRadius: "4px",
  fontFamily: "Lato, sans-serif",
  "& .is-default-css": {
    borderBottom: "1px solid #D5D5D5",
    color: "#375280",
    paddingBottom: "10px",
    paddingLeft: "6px",
    paddingRight: "6px",
    lineHeight: "22px",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "Lato, sans-serif",
  },
  "& .dropdown-default-css": {
    fontSize: "12px",
    color: "#375280",
    fontWeight: "600",
    paddingRight: "6px",
    lineHeight: "22px",
    paddingLeft: "6px",
    fontFamily: "Lato, sans-serif",
  },
  "& .delete-css": {
    paddingLeft: "6px",
    lineHeight: "22px",
    paddingRight: "6px",
    fontWeight: "600",
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato, sans-serif",
  },
});
