import React, { Component } from "react";
import { Box, createTheme, styled, ThemeProvider } from "@material-ui/core";
import Header from "../../../../components/src/Header";
import CheckoutSteps from "../../../productdescription3/src/CheckoutSteps.web";
import Footer from "../../../../components/src/Footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
    },
  },
});

interface Props {
  navigation: any;
  transPay: (transKey: string) => string;
  children: React.ReactNode;
  activeBreadCrumb: "address" | "cart" | "payment";
}

interface S {}

class BuyerCartWrapper extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigation, activeBreadCrumb, transPay, children } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Header navigation={navigation} />
        <BuyerMainContainer>
          <CheckoutSteps
            activeBread={activeBreadCrumb}
            transStepLang={transPay}
          />
        </BuyerMainContainer>
        <Box>{children}</Box>
        <Footer navigation={navigation} />
      </ThemeProvider>
    );
  }
}

export default BuyerCartWrapper;

const BuyerMainContainer = styled(Box)({
  backgroundColor: "rgb(248, 248, 248)",
  padding: "5px",
});
