import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ProductCartDetailController, {
  Props,
} from "./ProductCartDetailController";
import BuyerCartWrapper from "../components/BuyerCartWrapper";
import CommonBackdrop from "../../../../components/src/BackDrop";
import { Noimageavailable } from "../../../customisableuserprofiles2/src/assets";
import i18n from "../../../../components/src/i18next/i18n";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import { checkCondition } from "../../../../components/src/Seller/logOut";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ProductCartDetail extends ProductCartDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderCartData = () => {
    const { cartData, currencySign } = this.state;
    if (!cartData.cart_item) return <></>;

    const {
      product_quantity,
      primary_display_image,
      product_name,
      color,
      size,
      price_per_unit,
      shipping_cost,
      total_amount,
    } = cartData.cart_item.data.attributes;
    const id = cartData.cart_item.data.id;
    const subTotal = this.multiplyAndFormat(product_quantity, +price_per_unit);
    return (
      <>
        <TableContainer>
          <StyledTable aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow className="product-cart-row">
                <StyledTableHead className="product-cart-cell">
                  {this.transPD("PRODUCT DETAILS ")} ({product_quantity})
                </StyledTableHead>
                <TableCell className="product-cart-cell" align="center">
                  {this.transPD("PRICE")}
                </TableCell>
                <TableCell className="product-cart-cell" align="center">
                  {this.transPD("QUANTITY")}
                </TableCell>
                <TableCell className="product-cart-cell" align="center">
                  {this.transPD("SHIPPING COST")}
                </TableCell>
                <TableCell className="product-cart-cell" align="center">
                  {this.transPD("SUBTOTAL")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow key={id}>
                <StyledTableCell dir={i18n.dir()} data-test-id="table-cell">
                  <Box className="body-first-cell">
                    <Box className="img-container">
                      <img
                        data-test-id="navigateToDetail"
                        src={
                          checkCondition(
                            primary_display_image === "",
                            Noimageavailable,
                            primary_display_image
                          ) as string
                        }
                        alt="product-img"
                        className="img-css"
                      />
                    </Box>
                    <Box className="product-summary-container">
                      <Typography className="product-title">
                        {" "}
                        {product_name}
                      </Typography>
                      <Typography className="product-detail">
                        {this.transPD("Color: ")}
                        {color}
                      </Typography>
                      <Typography className="product-detail">
                        {this.transPD("Size: ")}
                        {size}
                      </Typography>
                    </Box>
                  </Box>
                </StyledTableCell>
                <TableCell align="center" className="product-cart-body-cell">
                  {currencySign} {price_per_unit}
                </TableCell>
                <TableCell align="center" className="product-cart-body-cell">
                  {product_quantity}
                </TableCell>
                <TableCell align="center" className="product-cart-body-cell">
                  {currencySign} {shipping_cost}
                </TableCell>
                <TableCell align="center" className="product-cart-body-cell">
                  {currencySign} {subTotal}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </StyledTable>
        </TableContainer>
        <PlaceOrderBox>
          <Box className="place-order-container-at-end">
            <Box className="place-order-right">
              <Box className="place-order-right1">
                <Box className="place-order-space-between">
                  <Typography className="shipping-fee-css">
                    {this.transPD("Item Total ")}
                  </Typography>
                  <Typography className="shipping-fee-css">
                    {currencySign} {subTotal}
                  </Typography>
                </Box>
                <Box className="place-order-space-between margin-10">
                  <Typography className="shipping-fee-css">
                    {this.transPD("Shipping fee")}
                  </Typography>
                  <Typography className="shipping-fee-css">
                    {currencySign} {shipping_cost}{" "}
                  </Typography>
                </Box>
                <Divider className="divider" />
                <Box className="place-order-space-between margin-20">
                  <Typography className="total-cost-css">
                    {this.transPD("Total Cost")}
                  </Typography>
                  <Typography className="total-cost-css">
                    {currencySign} {total_amount}
                  </Typography>
                </Box>
              </Box>
              <Box className="btn-container">
                <button
                  className="proceed-to-checkout-btn"
                  data-test-id="proceedToCheckout"
                  onClick={this.proceedToCheckout}
                >
                  {this.transPD("Proceed to Checkout")}
                </button>
                <button
                  className="continue-shopping-btn"
                  onClick={this.contioneShopping}
                  data-test-id="continueShopping"
                >
                  {this.transPD("Continue Shopping")}
                </button>
              </Box>
            </Box>
          </Box>
        </PlaceOrderBox>
      </>
    );
  };

  // Customizable Area End
  render() {
    const { isAlert, isBackDrop, message, severity } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BuyerCartWrapper
          navigation={this.props.navigation}
          activeBreadCrumb="cart"
          transPay={this.transPD}
          data-test-id="buyer-cart-id"
        >
          <Box>{this.renderCartData()}</Box>
          <CommonBackdrop open={isBackDrop} />
        </BuyerCartWrapper>
        <ReusableSnackbar
          open={isAlert}
          message={message}
          onClose={this.onAlertSnackClose}
          severity={severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyledTable = styled(Table)({
  width: "100%",
  border: "1px solid #F4F4F4",
  overflow: "hidden",
  backgroundColor: "#f8f8f8",
  "& .product-cart-row": {
    height: "76px",
  },
  "& .product-cart-cell": {
    background: "#CCBEB1",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "18px",
    minWidth: "150px",
    fontFamily: "Lato",
    whiteSpace: "nowrap",
  },
  "& .product-cart-body-cell": {
    color: "#375280",
    fontWeight: 500,
    fontSize: "18px",
    minWidth: "max-content",
    fontFamily: "Lato",
  },
});

const StyledTableHead = styled(TableCell)({
  padding: "10px 100px",
});

const StyledTableCell = styled(TableCell)(
  ({ dir }: { dir: "ltr" | "rtl" }) => ({
    padding: checkCondition(
      dir === "rtl",
      "50px 100px 50px 0px",
      "50px 0 50px 100px"
    ) as string,
    "& .body-first-cell": {
      display: "flex",
      color: "#375280",
      fontWeight: 500,
      fontSize: "18px",
      minWidth: "max-content",
      fontFamily: "Lato",
      "& .img-container": {
        boxShadow: "none",
        height: "120px",
        width: "105px",
        overflow: "hidden",
        borderRadius: "12px",
        "& .img-css": {
          height: "120px",
          width: "105px",
          borderRadius: "12px",
        },
      },
      "& .product-summary-container": {
        display: "flex",
        justifyContent: "center",
        minWidth: "max-content",
        alignItems: "baseline",
        margin: checkCondition(
          dir === "rtl",
          "0 20px 0 0",
          "0 0 0 20px"
        ) as string,
        flexDirection: "column",
        "& .product-title": {
          fontWeight: 700,
          fontSize: "16px",
          fontFamily: "Lato",
        },
        "& .product-detail": {
          fontWeight: 400,
          fontSize: "14px",
          color: "#94A3B8",
          fontFamily: "Lato",
        },
      },
    },
  })
);

const StyledTableRow = styled(TableRow)({
  "&:last-child td, &:last-child th": { border: 0 },
  "&:th": { margin: "0 500px" },
});

const PlaceOrderBox = styled(Box)({
  padding: "29px 99px 52px 99px",
  background: "#F4F4F4",
  "& .place-order-container-at-end": {
    display: "flex",
    justifyContent: "flex-end",
    "& .place-order-right": {
      minWidth: "465px",
      textAlign: "center",
      "& .place-order-right1": {
        padding: "10px 88px 0 91px",
        "& .divider": {
          backgroundColor: "#BEBCBD",
        },
        "& .shipping-fee-css": {
          fontWeight: 700,
          fontSize: "16px",
          fontFamily: "Lato",
          color: "#94A3B8",
        },
        "& .place-order-space-between": {
          display: "flex",
          justifyContent: "space-between",
        },
        "& .place-order-space-between.margin-10": {
          margin: "10px 0",
        },
        "& .place-order-space-between.margin-20": {
          margin: "20px 0",
          "& .total-cost-css": {
            fontWeight: 700,
            fontSize: "24px",
            fontFamily: "Lato",
            color: "#375280",
          },
        },
      },
      "& .btn-container": {
        padding: "10px 88px 0px 91px",
        "& .proceed-to-checkout-btn": {
          cursor: "pointer",
          color: "#fff",
          background: "#CCBEB1",
          fontFamily: "Lato",
          width: "100%",
          height: "64px",
          border: "1px solid #CCBEB1",
          fontWeight: 700,
          fontSize: "18px",
          marginTop: "30px",
          borderRadius: "2px",
          textTransform: "none",
        },
        "& .continue-shopping-btn": {
          cursor: "pointer",
          color: "#375280",
          width: "232px",
          height: "44px",
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "16px",
          border: "1px solid #CCBEB1",
          marginTop: "36px",
          borderRadius: "2px",
          textTransform: "none",
        },
      },
    },
  },
});
// Customizable Area End
