import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Divider, Modal, Typography } from "@material-ui/core";
import BuyerStylishCartPaymentDetailController, {
  Props,
} from "./BuyerStylishCartPaymentDetailController";
import StylishBuyerHeader from "../../../../components/src/StylishBuyerHeader";
import Header from "../../../../components/src/Header";
import { modalSuccessImg, Noimageavailable } from "../../../productdescription3/src/assets";
import CheckoutSteps from "../../../productdescription3/src/CheckoutSteps.web";
import i18n from "../../../../components/src/i18next/i18n";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import CommonBackdrop from "../../../../components/src/BackDrop";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class BuyerStylishCartPaymentDetail extends BuyerStylishCartPaymentDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderShippingContiner = () => {
    const { address_name, contact_number, house_or_building_number, street, city, zipcode, area } = this.state.activeCartView?.attributes?.shipping_address[0] || {}
    return <Box className="shippingContainer" dir={i18n.dir()}>
      <Typography className="shippingTitle">
        {this.transCartPay("Shipping Information")}
      </Typography>
      <Box className="billingBlock" >
        <Box className="addressMain">
          <Box>
            <Typography className="addressSubHead" style={{ textAlign:'center'}}>
              {address_name}
            </Typography>
          </Box>
          <Box>
            <Typography className="addressSubHead">
              {contact_number}
            </Typography>
          </Box>
          <Typography data-test-id="editAddress" 
            className="addressContextMenu"
            onClick={this.navigateToShippingAddressPage}
          >
            {this.transCartPay("Edit")}
          </Typography>
        </Box>
        <Box>
          {house_or_building_number || street || area && <Typography className="fullAddress">
          {house_or_building_number}, {street}, {area}
          </Typography>} 
          {city || zipcode && <Typography className="fullAddress">
          {city} - {zipcode}
          </Typography>}
        </Box>
      </Box>
      <Box display="flex" flexDirection='row' justifyContent="space-between" gridGap='8px' mb={3}>
        <Typography className="fullAddress" style={{color:'#375280'}}>
          {this.transCartPay("Estimated Delivery time")}
        </Typography>
        <Typography className="fullAddress" style={{textAlign:'end'}}>
          {this.state.activeCartView?.attributes?.estimated_arrival_time}
        </Typography>
      </Box>
    </Box>
  }

  renderOrderSummaryContainer = () => {
    const {primary_display_image, product_name, price_per_unit, color, product_quantity, size, shipping_cost, total_amount} = this.state.activeCartView.attributes || {}
    const itemsPrice = product_quantity && price_per_unit ? (Number(product_quantity)*Number(price_per_unit)) : 0
    return <Box className="summaryContainer"  dir={i18n.dir()}>
      <Box className="sumaryCard">
        <Typography className="summaryTitle" >
          {this.transCartPay("Order Summary")}
        </Typography>
        <Divider style={{ borderColor: "#EDEEF2" }} />
        <Box className="itemContainer" my="1rem">
          <Box>
            <img
              src={primary_display_image ? primary_display_image : Noimageavailable}
              width={63}
              height={63}
              style={{ borderRadius: "4px" }}
            />
          </Box>
          <Box my="auto" width="100%">
          <Box className="spaceBetween">
            <Typography  className="itemName">
              {product_name}{" "}
              <span className="fullAddress">x {product_quantity}</span>
            </Typography>
            <Typography className="fullAddress" style={{marginLeft: 'auto'}}>{this.state.currencySign} {itemsPrice}</Typography>
          </Box>
          <Typography className="itemName">
              {this.transCartPay("Size :")} <span className="fullAddress">{size}</span>
          </Typography>
          <Typography className="itemName">
            {this.transCartPay("Color :")} <span className="fullAddress">{color}</span>
          </Typography>   
          </Box>
        </Box>
        <Divider style={{ borderColor: "#EDEEF2" }} />
        <Box mt="1rem" mb="2rem">
          <Box className="spaceBetween" mb="0.75rem">
            <Typography className="savedMethodData">
              {this.transCartPay("Item total")} <span style={{color: "#94A3B8"}}>( {product_quantity} {this.transCartPay("items")} )</span>
            </Typography>
            <Typography className="savedMethodData" style={{marginLeft: 'auto'}}>{this.state.currencySign} {itemsPrice}</Typography>
          </Box>
          <Box className="spaceBetween" mb="0.75rem">
            <Typography className="savedMethodData">
              {this.transCartPay("Delivery Charges")}
            </Typography>
            {shipping_cost && <Typography className="savedMethodData" style={{marginLeft: 'auto'}}>
              {this.state.currencySign} {Number(shipping_cost).toFixed(2)}
            </Typography>}
          </Box>
          
          <Divider style={{ borderColor: "#EDEEF2" }} />
          <Box className="spaceBetween" my="0.75rem">
            <Typography className="totalText">{this.transCartPay("Total Cost")}</Typography>
            {total_amount && <Typography className="totalText" style={{marginLeft: 'auto'}}>{this.state.currencySign} {Number(total_amount).toFixed(2)}</Typography>}
          </Box>
        </Box>
        <Button className="placeOrderBtn" 
          data-test-id="placeOrder" 
          onClick={this.placeOrder}
        >
            {this.transCartPay("Place Order")}
        </Button>
      </Box>
    </Box>
  }

  renderSuccessModal = () => {
    return <SuccessModal open={this.state.isSuccessModal} style={{display: 'flex'}} dir={i18n.dir()}>
      <Box className="modalBox" dir={i18n.dir()}>
        <img src={modalSuccessImg} />
        <Typography className="modalHead">{this.transCartPay("Great!")}</Typography>
        <Typography style={{ fontSize: "1.5rem" }}>
          {this.transCartPay("Your order has been placed successfully.")}
        </Typography>
        <Button className="placeOrderBtn2" data-test-id="proceedToTrackOrderPage" onClick={this.navigateToChatPage}>
          {this.transCartPay("Go to chat")}
        </Button>
      </Box>
    </SuccessModal>
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.isStylistBuyer ? <StylishBuyerHeader navigation={this.props.navigation} />:<Header navigation={this.props.navigation} />}
        <Box px={3}><CheckoutSteps activeBread="payment" transStepLang={this.transCartPay} /></Box>
        <MainContainer dir={i18n.dir()}>
          {this.renderShippingContiner()}
          {this.renderOrderSummaryContainer()}
        </MainContainer>
        <CommonBackdrop open={this.state.isLoading} />
        {this.renderSuccessModal()}
        <ReusableSnackbar
          open={this.state.isError}
          message={this.state.errMsg}
          onClose={this.onCloseSnack}
          severity={"error"}
          dataTestId="chatCartPaymentSnack"
          autoHideDuration={3500}
          dir={i18n.dir()}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const MainContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Lato',
  padding: "0 2.25rem 5.25rem",
  backgroundColor: "#F8F8F8",
  gap: "2.5rem",
  flexDirection: "row",
  '@media (max-width: 768px)': {
    flexDirection: "column",
    gap: '1rem',
  },
  '@media (max-width: 442px)': {
    padding: "0 1.25rem 3.25rem",
  },
  "& .shippingContainer":{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    "& .shippingTitle":{
      fontSize: '1.5rem',
      fontWeight: 700, 
      fontFamily: 'Lato',
      marginBottom: "0.5rem",
      color: "#375280",
    },
    "& .billingBlock": {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 8px 0px #00000014",
      padding: "1rem 1rem 1.5rem",
      margin: "0.5rem 0 1rem",
      borderRadius: "2px",
      "& .addressMain": { display: "flex", gap: "1.25rem" },
      "& .addressName": { backgroundColor: "#F6F6F6", textAlign: "center" as "center" },
      "& .addressHead": { color: "#94A3B8", fontFamily: 'Lato', fontWeight: 700, padding: "0.5rem 0" },
      "& .addressData": { color: "#375280", fontFamily: 'Lato', },
      "& .addressSubHead": { color: "#375280", fontFamily: 'Lato', fontWeight: 700, padding: "0.5rem 0" },
      "& .addressContextMenu": {
        color: "#375280",
        marginRight: "0",
        fontFamily: 'Lato',
        marginLeft: "auto",
        cursor: "pointer"
      },
     
    },
  },
  "& .fullAddress": { color: "#94A3B8", fontFamily: 'Lato', fontSize: "0.875rem" },

  "& .summaryContainer": {
    width: '100%',
    "& .sumaryCard":{
      backgroundColor: "#FFF",
      borderRadius: "2px",
      padding: "1.5rem 1rem",
      height: "fit-content",
      boxShadow: "2px 2px 4px 0px #0000000D,-2px -2px 4px 0px #0000000D",
    },
    "& .summaryTitle": {
      color: "#375280",
      fontSize: "1.5rem",
      fontWeight: 700,
      marginTop: '0.5rem',
      marginBottom: '1rem'
    },
    "& .itemContainer":{
      display: 'flex',
      gap: '1rem',
      "& .itemName": {
        fontSize: "0.875rem",
      },
    },
    "& .spaceBetween": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "2px",
      flexWrap: 'wrap',
    },
    "& .savedMethodData": {
      fontSize: "1.125rem",
      color: '#375280',
      fontWeight: 500,
    },
    "& .totalText": {
      fontSize: "1.125rem",
      fontWeight: 700,
      color:"#375280"
    },
    "& .placeOrderBtn": {
      width: "100%",
      backgroundColor: "#C7B9AD",
      marginTop: "1rem",
      padding: "1rem 0",
      fontSize: "1.125rem",
      fontWeight: 700,
      fontFamily: "Lato, sans-serif",
      color: "#fff",
      textTransform: "none" as "none",
  },
  }
})

const SuccessModal = styled(Modal)({
  display: 'flex',
  "& p":{
    color: '#375280',
    fontFamily: 'Lato',
  },
  "& .modalBox": {
    background: "#fff",
    width: "30vw",
    margin: "auto",
    borderRadius: "2px",
    padding: "3rem",
    textAlign: "center" as "center",
  },
  "& .modalHead": {
    fontSize: "1.875rem",
    fontWeight: 700,
    marginTop: "2.5rem",
    marginBottom: "0.5rem",
  },
  "& .placeOrderBtn2": {
    width: "75%",
    backgroundColor: "#C7B9AD",
    marginTop: "1rem",
    padding: "1rem 0",
    fontSize: "1.125rem",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    textTransform: "none" as "none",
  },
  
})
// Customizable Area End
