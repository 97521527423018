import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import i18n from "../../../../components/src/i18next/i18n";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../components/src/APICall";
import { ErrorMessage } from "../../../customform/src/CreateSellerStoreController";
import { IGetCartResponse } from "./ProductCartDetailController";
import { IAddAddressResp } from "./AddShippingAddressManagementController";
import { checkCondition } from "../../../../components/src/Seller/logOut";
// Customizable Area Start
export interface IShowAddress {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zipcode: string;
  area: string;
  block: string;
  city: string | null;
  house_or_building_number: string;
  floor: string | null;
  address_name: string;
  is_default: boolean;
  latitude: number | null;
  longitude: number | null;
  country_code_name: string | null;
}

export interface IAddUpdateAddress extends IShowAddress {
  id: string
}

export interface AddressData {
  id: string;
  type: string;
  attributes: IShowAddress;
}

interface IGetAddressResp {
  data: AddressData[];
}

export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openDropdownId: string | null;
  addressData: AddressData[];
  showaddressData: AddressData;
  currencySign: string;
  isDeleteDialogOpen: boolean;
  addressToDelete: string | null;
  selectedAddressId: string;
  isAlert: boolean;
  alertType: "error" | "success";
  alertMsg: string;
  isBackDrop: boolean;
  isAddressBackDrop: boolean;
  cartData: IGetCartResponse;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShippingAddressDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateAddressAPICallId: string = "";
  deleteAddressAPICallId: string = "";
  getBuyerStylishCartAPICallId: string = "";
  getAddressAPICallId: string = "";
  updateOrderAddressAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      openDropdownId: null,
      addressData: [],
      showaddressData: {} as AddressData,
      currencySign: "$",
      isDeleteDialogOpen: false,
      addressToDelete: null,
      selectedAddressId: "",
      isAlert: false,
      alertType: "error",
      alertMsg: "",
      isBackDrop: true,
      isAddressBackDrop: true,
      cartData: {} as IGetCartResponse,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseShippingAddressJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallShippingAddressId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseShippingAddressJson && !responseShippingAddressJson.errors) {
        this.apiSuccessShippingAddressCall(apiRequestCallShippingAddressId, responseShippingAddressJson);
      } else if (responseShippingAddressJson && responseShippingAddressJson.errors) {
        this.apiFailureShippingAddressCall(apiRequestCallShippingAddressId, responseShippingAddressJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiSuccessShippingAddressCall = async (
    apiRequestCallId: string,
    responseJson: IGetCartResponse & IGetAddressResp & {message: string} & IAddAddressResp
  ) => {
    switch (apiRequestCallId) {
      case this.getBuyerStylishCartAPICallId: {
        await this.getBuyerStylishCartResp(responseJson)
        break;
      }

      case this.updateOrderAddressAPICallId:{
        this.updateOrderAddressResp(responseJson);
        break;
      }

      case this.getAddressAPICallId: {
        this.getAddressResp(responseJson);
        break;
      }

      case this.deleteAddressAPICallId:{
        if (responseJson.message) this.getDataAddress();
        break;
      }

      case this.updateAddressAPICallId:{
        if (responseJson.data && responseJson.meta) this.getDataAddress();
        break;
      }

      default:
        break;
    }
  };

  getBuyerStylishCartResp = async (responseJson: IGetCartResponse) =>{
    if (responseJson?.cart_item) {
      this.setState({ cartData: responseJson, isBackDrop: false, selectedAddressId: responseJson.cart_item.data.attributes.shipping_address[0].id.toString() });
      await setStorageData("selectedAddressID", responseJson.cart_item.data.attributes.shipping_address[0].id.toString());
    }
  }

  updateOrderAddressResp = (responseJson: { message: string }) => {
    if(responseJson.message){
      this.setState({alertType: "success",
        alertMsg: responseJson.message,
        isAlert: true}, ()=> setTimeout(() => this.getNavigationToPath("BuyerStylishCartPaymentDetail")
      , 800))
    }
  }

  getAddressResp = async (responseJson: IGetAddressResp) =>{
    if (responseJson?.data) {
      const defaultAddress = responseJson.data.find(
        (address) => address?.attributes?.is_default
      );
      let addressStorageId: string = "";
      if (defaultAddress) {
        const addressId = await getStorageData("selectedAddressID");
        
        if(addressId===null){
          addressStorageId = defaultAddress?.id || "";
          await setStorageData("selectedAddressID", defaultAddress.id);
        }
        else{
          addressStorageId = addressId;
        }
      }

      this.setState({
        addressData: responseJson.data ?? [],
        selectedAddressId: addressStorageId,
        isAddressBackDrop: false
      });
    }
  }

  getDataAddress = () =>{
    this.setState((prevState)=>({
      ...prevState,
      isAddressBackDrop: true,
    }), async ()=> {
      await this.getAddress();
      this.scrollToSection("add-address-id");
    })
  }

  scrollToSection = (sectionIdName:string) => {
    const section = document.getElementById(sectionIdName);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  apiFailureShippingAddressCall = (apiRequestCallId: string, responseErrorJson: ErrorMessage & { error: string }) => {
    if (responseErrorJson.errors[0].token) {
      this.handleFailure(apiRequestCallId, responseErrorJson.errors[0].token);
    } else {
      this.handleFailure(apiRequestCallId, responseErrorJson.error);
    }
  };

  handleFailure = (apiRequestCallId:string, message: string) => {
    this.setState((prevState)=>({
      ...prevState,
      alertType: "error",
      alertMsg: message,
      isAlert: true,
      isBackDrop: false,
      isAddressBackDrop: checkCondition(apiRequestCallId===this.getAddressAPICallId, false, prevState.isAddressBackDrop) as boolean
    }));
  };

  async componentDidMount() {
    // Customizable Area Start
    const currencySign = await getStorageData("userRole", true);
    if (currencySign.currencyLogo) {
      this.setState({ currencySign: currencySign.currencyLogo });
    }
    await this.getAddress();
    await this.getBuyerCart();
    // Customizable Area End
  }

  transPay = (payKey: string) => {
    return i18n.t(payKey, { ns: "shoppingCart" });
  };

  getAddress = async () => {
    const token = await this.getToken();
    this.getAddressAPICallId = await apiCall({
      token: token,
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.addressEndPoint,
    });
  };

  getBuyerCart = async () => {
    const order_id = await getStorageData("stylish_buyer_cart_id");
    const orderToken = await this.getToken();
    this.getBuyerStylishCartAPICallId = await apiCall({
      token: orderToken,
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.buyerStylishCartGetEndPoint}${order_id}`,
    });
  };

  getNavigationToPath = (path: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  toggleDropdown1 = (addressID: string) => {
    this.setState((prevState) => ({
      openDropdownId: prevState.openDropdownId === addressID ? null : addressID
    }));
  };

  handleMakeDefault = (addressID: string) => {
    const updatedAddresses = this.state.addressData.map(
      (address: AddressData) => {
        if (address.id === addressID) {
          return {
            ...address,
            attributes: { ...address.attributes, is_default: true },
          };
        } else {
          return {
            ...address,
            attributes: { ...address.attributes, is_default: false },
          };
        }
      }
    );
    this.setState({
      showaddressData:
        updatedAddresses.find((address) => address.id === addressID) ||
        ({} as AddressData),
    });
    this.updateSubmit();
    this.setState({
      addressData: updatedAddresses,
      openDropdownId: null,
    });
  };

  getToken = async () => {
    return await getStorageData("auth-token");
  };

  updateSubmit = async () => {
    const addressID = await getStorageData("addressID");
    const token = await this.getToken();
    const formData = new FormData();
    const {
      name,
      area,
      block,
      street,
      house_or_building_number,
      zipcode,
      city,
      country_code,
      phone_number,
      latitude,
      longitude,
      address_name,
      is_default,
    } = this.state.showaddressData.attributes;
    formData.append("name", name);
    formData.append("area", area);
    formData.append("block", block);
    formData.append("street", street);
    formData.append("house_or_building_number", house_or_building_number);
    formData.append("zipcode", zipcode);
    formData.append("city", city?.toString() || "");
    formData.append("country_code", country_code);
    formData.append("phone_number", phone_number);
    formData.append("latitude", latitude ? String(latitude) : "");
    formData.append("longitude", longitude ? String(longitude) : "");
    formData.append("address_name", address_name);
    formData.append("is_default", is_default.toString());

    this.updateAddressAPICallId = await apiCall({
      token: token,
      body: formData,
      isJsonStringify: false,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.addressEndPoint}/${addressID}`,
    });
  };

  updateAddressToOrder = async () =>{
    const order_id = await getStorageData("stylish_buyer_cart_id");
    const addressID = await getStorageData("selectedAddressID")
    const token = await this.getToken();
    this.updateOrderAddressAPICallId = await apiCall({
      token: token,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.addAddressToOrderEndPoint}${order_id}&address_id=${addressID}`,
    });
    this.setState({isBackDrop: true})
  }

  handleOpenDeleteDialog = (addressID: string) => {
    this.setState({ isDeleteDialogOpen: true, addressToDelete: addressID });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ isDeleteDialogOpen: false, addressToDelete: null });
  };

  onAlertSnackClose = () => {
    this.setState({ isAlert: false });
  };

  handleConfirmDelete = () => {
    this.deleteAddress(this.state.addressToDelete);
    this.handleCloseDeleteDialog();
  };

  deleteAddress = async (addressID: string | null) => {
    const token = await this.getToken();
    this.deleteAddressAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethod,
      endPoint: `${configJSON.addressEndPoint}/${addressID}`,
      token: token,
    });

    const updatedAddressData = this.state.addressData.filter(
      (address) => address.id !== addressID
    );
    this.setState({ addressData: updatedAddressData });
  };

  handlePlaceOrder = async () => {
    if (!this.state.selectedAddressId) {
      this.setState({
        isAlert: true,
        alertType: "error",
        alertMsg: this.transPay("Please select an address."),
      });
    } else {
      await this.updateAddressToOrder();
    }
  };

  handleCheckboxChange = async (addressID: string) => {
    this.setState({ selectedAddressId: addressID });
    await setStorageData("selectedAddressID", addressID);
  };

  getEditNavigationToPath = async (addressID: string, path: string) => {
    await setStorageData("selectedAddressID", addressID);
    
    this.getNavigationToPath(path)
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
