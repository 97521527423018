import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider, Grid, Accordion, AccordionDetails, AccordionSummary, Card, Snackbar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import i18n from "../../../components/src/i18next/i18n";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { cartpng, sherpng, image_Likepng, palritpng, Noimageavailable,viewLike, tryitout } from "./assets"
import { styled } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Header from "../../../components/src/Header"
import Footer from "../../../components/src/Footer"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import ShareProductMenu from "../../../components/src/Seller/ShareProductMenu";
// Customizable Area End

import Productdescription3Controller, {
  Props,
  configJSON,
} from "./Productdescription3Controller";
import CommonBackdrop from "../../../components/src/BackDrop";
import { badge } from "../../loyaltysystem/src/assets";

export default class Productdescription3 extends Productdescription3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  imgFilter = () => {
    const filteredImages = this.state.renderImage?.filter(filterImg => filterImg !== "");
    
    return filteredImages.length > 0 ? filteredImages.map((filteredValue, index: number) => this.renderImg(filteredValue, index)) : [this.renderImg(Noimageavailable, 0)];
  }

  renderImg = (filteredValue:string, index: number) =>(
    <div key={index} className="active-img" style={{ 
    backgroundImage: `url(${filteredValue === "" ? Noimageavailable : filteredValue})`, 
  }}>
          <div style={{ ...webStyle.legendBox, flexDirection: "row" }} className="legend">
            <div style={webStyle.shreImg} data-testid="Share" className="legend" onClick={this.handleShareProduct}><img src={sherpng} style={webStyle.shreImgBlock} /></div>
            <div style={{...webStyle.likeimg,cursor:"pointer"}} className="legend" data-testid={`wishlistLikeadd=${this.state.productsData.id}`} onClick={()=>this.handleWishlistToggle(+this.state.productsData.id)}><img src={this.state.productsData && this.state.productsData.attributes.is_wishlist ?  viewLike : image_Likepng} style={{...webStyle.likeImgBlock,cursor:"pointer"}} /></div>
          </div>
          {this.state.productsData && this.state.productsData.attributes.catalogue_variants_with_store[0].attributes.pair_it_with.length ? <span style={webStyle.palritImg} className="legend" data-testid="Pairitwith" onClick={() => this.state.productsData && this.state.productsData.attributes.catalogue_variants[0].attributes.pair_it_with.length ?  this.navigateToData({ productId: this.state.productsData && this.state.productsData.id, props: this.props, screenName: "PairitwithWeb" }):this.showeErrorParir()}>{this.transDesc("PAIR IT WITH")}&nbsp;<img style={webStyle.palritImgBlock} src={palritpng} /></span>:null}
          <span  style={webStyle.tryItOutImg} className="legend zyler-try-on-cta" data-outfit-handle="totem5-p606029512" data-testid="TryitOut"><img style={webStyle.TryitImgBlock} src={tryitout} />&nbsp;{"TRY IT OUT"}</span >
        </div>
  )

  imgFilter2 = () => {
    const filteredImages = this.state.renderImage?.filter(filterImg => filterImg !== "");
    
    return filteredImages.length > 0 ? filteredImages.map((filteredValue, index: number) => this.renderImgBelow(filteredValue, index)) : [this.renderImgBelow(Noimageavailable, 0)];
  }

  renderImgBelow = (filteredValue:string, index: number) =>(
    <Box key={index}>
            <Box style={{ ...webStyle.styleimg, position: "relative", border: this.state.selectedItem === index ? '1px solid #CCBEB1' : '' }} data-testid={`handleSelect-${index}`} onClick={() => this.handleSelect(index)}>
              <img
                alt={`img-${index}`}
                style={{ ...webStyle.stylebox, objectFit: "fill" }}
                src={filteredValue === "" ? Noimageavailable : filteredValue}
              />
            </Box>
          </Box>
  )

  renderAccordian = () => {
    return <Box style={{ padding: "71px 92px" }}>
      <Box style={{ padding: "71px 92px" }}>
        <AccordionDetailsData >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={webStyle.title}>{this.transDesc("Product description")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={webStyle.discription}>{this.state.productsData?.attributes.description}</Typography>
          </AccordionDetails>
        </AccordionDetailsData>
        <AccordionDetailsData >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={webStyle.title}>{this.transDesc("Size & Fit")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={webStyle.discription} dir={i18n.dir()}><span>{this.transDesc("Fit : ")}</span>{this.state.productsData?.attributes.fit}<br/>{this.state.productsData?.attributes.fit_discription !== null && this.state.productsData?.attributes.fit_discription}</Typography>
          </AccordionDetails>
        </AccordionDetailsData>
        <AccordionDetailsData >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={webStyle.title}>{this.transDesc("Product care & Material")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={webStyle.discription} dir={i18n.dir()}>{this.transDesc("Material - ")}{this.state.productsData?.attributes.material}<br />{this.state.productsData?.attributes.prodcut_care}</Typography>
          </AccordionDetails>
        </AccordionDetailsData>
        <AccordionDetailsData >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={webStyle.title}>{this.transDesc("Expected Delivery & Total Cost")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={webStyle.discription}>
              {this.transDesc("Delivery by:")} {this.formattedDate()} <br />
              <Box style={{ display: 'flex', width: '32%' }}>
                <Typography style={webStyle.discription}>{this.transDesc("Product Price : ")}</Typography>
                <Typography style={webStyle.discription}>{`${this.state.userCurrency} ${this.state.productsData?.attributes.primary_price
                  ? parseFloat(this.state.productsData.attributes.primary_price).toFixed(2)
                  : "0.0"}`}</Typography>
              </Box>
              <Box style={{ display: 'flex', width: '32%' }}>
                <Typography style={webStyle.discription}>{this.transDesc("Approx Delivery Cost :")} </Typography>
                <Typography style={webStyle.discription}>{this.state.userCurrency} 10.00</Typography>
              </Box>
              <Box style={{ display: 'flex', width: '32%' }}>
                <Typography style={{ fontFamily: "Lato", fontSize: '16px', fontWeight: 700, color: '#375280', width: "100%" }}>{this.transDesc("Total Cost : ")}</Typography>
                <Typography style={{ fontFamily: "Lato", fontSize: '16px', fontWeight: 700, color: '#375280', width: "100%" }}>
                  {`${this.state.userCurrency} ${this.state.productsData?.attributes.primary_price
                      ? (parseFloat(this.state.productsData.attributes.primary_price) + 10).toFixed(2)
                      : "0.00"
                    }`}
                </Typography>
              </Box>
            </Typography>
          </AccordionDetails>
        </AccordionDetailsData>
        <AccordionDetailsData >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={webStyle.title}>{this.transDesc(this.state.productsData.attributes.catalogue_variants_with_store[0] && this.state.productsData.attributes.catalogue_variants_with_store[0].store_info.attributes?.store_name ? "Store information" : "Stylist information")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {this.state.productsData.attributes.catalogue_variants_with_store[0] && this.state.productsData.attributes.catalogue_variants_with_store[0].store_info.attributes?.store_name ?
                <>
                  <Box style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280", width: "100%", height: "3px" }}>
                    {this.state.productsData.attributes.catalogue_variants_with_store[0] && this.state.productsData.attributes.catalogue_variants_with_store[0].store_info.attributes?.store_name}
                  </Box><br />
                  <Box style={webStyle.discription}>{this.getAddress()}</Box>
                </>
                :
                <>
                  <Box style={{ fontFamily: "Lato", fontSize: '16px', fontWeight: 700, color: '#375280', width: "100%", height: '3px' }}>
                    {this.state.productsData.attributes && this.state.productsData.attributes.owner_full_name}</Box> <br />
                  <Box style={webStyle.discription}>{this.getAddressStylist()}</Box>
                </>
              }
            </Box>
          </AccordionDetails>
        </AccordionDetailsData>
      </Box>
    </Box>
  }

  renderCarousel1 = () => {
    const isShowArrow = this.state.renderImage.filter(filterImg => filterImg !== "").length > 1;

    const translatedText = this.transDesc("LOYALTY_POINTS_MESSAGE", {
      points: this.state.loyaltyPoint,
    });

    const textParts = translatedText.split("__image__");
    return <Box>
      <Grid container >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomCarousel>
            <div style={{ padding: i18n.dir() === "rtl" ? "0 0 0 43px" :"0 43px 0 0" }}>
              <div>
                <Carousel
                  selectedItem={this.state.selectedItem}
                  onChange={this.handleSelect}
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                >
                  {this.imgFilter()}
                </Carousel>
                <div style={webStyle.elementBlock}>
                  {isShowArrow && <div
                    style={webStyle.leftIcon}
                    data-testid='handleClick'
                    onClick={this.handleClick}
                  >
                    <ChevronLeftIcon style={{ color: "white", transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none' }} />
                  </div>}
                  <div style={webStyle.imgBlock}
                    ref={this.elementRef}
                  >
                    {this.imgFilter2()}

                  </div>
                  {isShowArrow && <div
                    style={webStyle.next}
                    data-testid="handleNextClick"
                    onClick={this.handleNextClick}
                  >
                    <NavigateNextIcon style={{ color: "white", transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none'}}  />
                  </div>}
                </div>
              </div>
            </div>
          </CustomCarousel>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} >
          <Box style={{}}>
            <Box>
              <Box style={{ padding: "34px 0px" }}>
                <Typography style={{ ...webStyle.text, cursor: 'pointer' }}><Box data-testid="homePageNavigation" onClick={() => this.homePageNavigation(this.props)}>{this.transDesc("Home")}</Box> <NavigateNextIcon style={{transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none' ,...webStyle.navigateIcon}} /> <Box data-testid="clothingPageNavigation" onClick={() => this.clothingPageNavigation(this.props, this.state.categoryData)}>{this.state.categoryData}</Box> <NavigateNextIcon style={{...webStyle.navigateIcon, transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none'}} /> {this.state.sub_categoryData}</Typography>
              </Box>
              <Typography style={webStyle.mainTitle}>
              {this.state.productsData?.attributes.brand_name} {this.state.productsData?.attributes.name}
              </Typography>
              <Typography style={{ ...webStyle.subTitle, letterSpacing: '1px' }}>
                {this.state.productsData?.attributes.description}
              </Typography>
              <Box style={webStyle.priceContainer}>
              <Box style={webStyle.priceOfferContainer}>{this.state.productDiscountedPercentage > 0 && `${this.state.productDiscountedPercentage}${this.transDesc("% Off")}`}
              <Typography id="primary-list-price" style={webStyle.Price}>{`${this.state.userCurrency} ${this.state.productDiscountedPrice}`}</Typography></Box>
              {this.state.productDiscountedPercentage > 0 && 
                    <Typography id="actual-list-price">
                      <del>{`${this.state.userCurrency} ${this.state.productPrice}`}</del>
                    </Typography>
                 }
              </Box>
              
              <Divider style={{ color: "#BEBCBD", width: "665px" }} />
            </Box>
            <Box style={{ marginTop: "30px" }}>
              <Box style={webStyle.sizeContainer}>
                <Typography style={webStyle.size} > {this.transDesc("Select Size")} </Typography>
                <Typography style={webStyle.selectedSize}>
                  {this.state.selectedSize ? `${this.state.selectedSizeLabel} ` : this.transDesc('No size selected')}
                </Typography>
              </Box>
              <Box style={webStyle.sizeContainer}>
                {this.state.initialSize.map((size: { name: string; label: string; }, index: number) => {
                  const isSelectedSize = this.state.selectedSize === size.label;
                  return this.state.filterSize.includes(size.label) && (
                    <Box
                      key={index}
                      style={{ ...webStyle.sizeBox, backgroundColor: this.backgroundColor(isSelectedSize), color: this.sizeColor(isSelectedSize) }}
                      data-testid={`handleSizeClick-${index}`}
                      onClick={() => this.handleSizeClick(size)}
                    >
                      <Box data-testid="selectedSize">{this.isSelectedSize(isSelectedSize, size)}</Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            <Box style={{ marginTop: "47px" }}>
              <Typography style={webStyle.color}>
                {this.transDesc("Colours Available")}
              </Typography>
              <Box style={webStyle.colorBox}>
                {this.state.filterColor.map((color, index) => (
                  <>
                    <Box style={{
                      ...webStyle.selectedDots, position: "relative",
                      border: this.filterColore(index)
                    }}>
                      <Box style={{ ...webStyle.insideDots, position: "absolute", backgroundColor: color, border: '1px solid #375280' }} data-testid={`handleColorClick-${index}`} onClick={() => this.handleColorClick(color, index)} />
                    </Box>
                  </>))}
              </Box>
            </Box>
            <Box style={{ marginTop: "177px", }}>
              {this.state.loyaltyPoint && <Typography style={webStyle.loyaltyPoint}>{textParts[0]} <img style={webStyle.badgeImg} src={badge} alt="loyalty-point-icon" /> {textParts[1]}</Typography>}
              <Box style={{ display: "flex", gap: "24px", }}>
                <ButtonAdd data-testid="getNavigationMessage" onClick={() => this.getNavigationMessage(this.props)}><img src={cartpng} style={{ marginRight: "10px" }} />{this.transDesc("Add to Cart")}</ButtonAdd>
                <Button style={{ ...webStyle.buttonBye, textTransform: "none" }} data-testid="buyNowPageNavigation" onClick={() => this.buyNowPageNavigation(this.props)}>{this.transDesc("Buy Now")}</Button></Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  }

  renderDiscount = (discount: number) => (
      <Box style={{ ...webStyle.productDiscountproductd, backgroundColor : discount > 0 ?  "#FFFFFF" : "transparent"}}>
        {discount > 0 && `${discount}${this.transDesc("% Off")}`}
      </Box>)

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box id="topId">
        {!this.state.stylishBuyer ? <Header navigation={this.props.navigation} /> : <StylishBuyerHeader navigation={this.props.navigation} /> }
        {this.state.lodingProduct ? <Box data-testid="loder" style={{ width: '100%', textAlign: 'center', paddingBottom: '20px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlignLast: 'center' }}><CircularProgress style={{ color: '#375280' }} /></Box>
          : <Box data-testid="loder12" dir={i18n.dir()}>
            {this.renderCarousel1()}
            {this.renderAccordian()}
            <ShareProductMenu
              data-testid="sharePopup"
              anchorEl={this.state.anchorEl}
              onClose={this.handleCloseMenu}
              onCopyLink={this.handleCopyLink}
              linkCopied={this.state.linkCopied}
              productUrl={this.state.productUrl}
              dir={i18n.dir()}
            />
            <Box style={{ padding: '0px 120px' }} dir={i18n.dir()}>
              <Typography style={webStyle.cart}>{this.transDesc("Similar Items")}</Typography>
              <div style={webStyle.cartLine} />
              <Grid container spacing={4} justifyContent="center">
                {this.state.products.map((product, index: number) => (<>
                  <Grid item key={index}>
                    <Box style={{ cursor: 'pointer' }} data-testid={`navigatedTo=${product.id}`} onClick={() => this.navigateTo({ productId: product.id, props: this.props, screenName: "Productdescription3" })}>
                      <Card style={webStyle.productBoxproductd}>
                        <Box style={{ position: 'relative' }}>
                          <img
                            style={{ position: 'relative' }}
                            width="100%" height="370px"
                            src={product.attributes.primary_image === null ? Noimageavailable : product.attributes.primary_image}
                            alt="Product"
                          />
                          <Box style={{ ...webStyle.productIconproductd, position: 'absolute' }}>
                            {this.renderDiscount(product.attributes.primary_discounted_percentage)}
                            <Box style={webStyle.productFavoriteproductd} data-testid={`wishlistLikeRemoved=${product.id}`} onClick={(event) => this.is_wishlistData(product,event)}><img src={product.attributes.is_wishlist ? viewLike :image_Likepng} width="18px" height="18px" /></Box>
                          </Box>
                        </Box>
                        <Box style={{ padding: '24px', backgroundColor: '#F8F8F8' }} onClick={this.oncloseAlert} data-testid="alertTestId">
                          <Box style={{ ...webStyle.productDataproductd, whiteSpace: 'nowrap', flexDirection: 'row' }}>
                            <Box style={{ ...webStyle.productValueproductd, whiteSpace: 'nowrap' }}>
                              <Typography style={{ ...webStyle.productNameproductd, whiteSpace: 'nowrap' }} >{product.attributes.name}</Typography>
                              <Typography style={{ ...webStyle.productDescriptionproductd, whiteSpace: 'nowrap' }} >{product.attributes.primary_store_name}</Typography>
                            </Box>
                            <Box style={{ ...webStyle.acountMrpproductd, textAlign: 'center', width: "85px" }}>{product.attributes.primary_discounted_percentage > 0 ? (
                              <>
                                <Typography id="actual-list-price">
                                  <del>{`${this.state.userCurrency} ${product.attributes.primary_main_price}`}</del>
                                </Typography>
                                <Typography id="discounted-list-price" style={webStyle.actualPrice}>{`${this.state.userCurrency} ${product.attributes.primary_price}`}</Typography>
                              </>
                              ) : (
                                <Typography id="primary-list-price" style={webStyle.actualPrice}>{`${this.state.userCurrency} ${product.attributes.primary_main_price}`}</Typography>
                            )}</Box>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                </>))}
                {this.state.favloading && <Box style={{ width: '54px', textAlign: 'center', paddingBottom: '20px', position: "absolute",borderRadius:"2px", top: "50%", backgroundColor: "#FFFFFF", left: "50%",height: "36px" , display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress style={{ color:  '#375280', marginTop: "20px" }} /></Box>}
              </Grid>
            </Box>
            
            {this.state.modalOpen && this.renderDialog()}
            <Snackbar
              open={this.state.isAlert}
              autoHideDuration={3000}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClose={this.oncloseAlert}
              dir={i18n.dir()}
            ><Alert variant="filled" severity={this.state.severity}>{this.state.already}</Alert></Snackbar>
          </Box>
        }
        <Footer navigation={this.props.navigation}/>
        <CommonBackdrop open={this.state.isBackDrop} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  navigateIcon: { width: "20px" },
  palritImgBlock: { width: "16px", height: "16px" },
  TryitImgBlock: { width: "22px", height: "22px" },
  likeImgBlock: { width: "24px", height: "24px", color: "#375280" },
  iconBlock: { marginTop: "40px", display: "flex", gap: "100px", },
  iconBoxContainer: { marginTop: "26px", display: "flex" },
  title: { fontWeight: 700, fontSize: "20px", fontFamily: "Lato" },
  discription: { fontWeight: 500, fontSize: "16px", fontFamily: "Lato", width: "100%", color: "#94A3B8" },
  text: { display: 'flex', color: "#375280", fontFamily: "Lato", gap: "8px" },
  productBoxproductd: { boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "286px", marginBottom: "20px" },
  shreImgBlock: { width: "24px", height: "24px" },
  leftIcon: { display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: "#CCBEB1", borderRadius: '50%', width: '38px', height: '38px', },
  imgBlock: { display: 'flex', gap: "20px", backgroundColor: "rgb(248, 248, 248)", overflow: 'hidden', maxWidth: '462px', borderRadius: '10px' },
  sizeContainer: { display: 'flex', gap: "20px", marginTop: "30px" },
  sizeBox: { width: '38px', height: '38px', border: '1.5px solid #CCBEB1', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', fontFamily: "Lato", fontWeight: 600, fontSize: '14px', borderRadius: '2px' },
  colorBox: { marginTop: "27px", display: "flex", gap: "10px" },
  next: { display: 'flex', cursor: 'pointer', backgroundColor: '#CCBEB1', borderRadius: '50%', width: '38px', height: '38px', justifyContent: 'center', alignItems: 'center', },
  stylebox: { width: '68px', height: '68px', borderRadius: "10px", margin: "4px", },
  styleimg: { width: '76px', backgroundColor: "rgb(248, 248, 248)", height: '76px', borderRadius: "10px", },
  elementBlock: { backgroundColor: "rgb(248, 248, 248)", display: "flex", padding: "20px 0", justifyContent: 'center', gap: "20px", alignItems: "center" },
  dfdsf: { backgroundColor: "rgb(248, 248, 248)", display: "flex", padding: "20px 0", justifyContent: 'center', gap: "20px", alignItems: "center" },
  palritImg: { display: "flex", alignItems: `${i18n.dir() === 'ltr' ? 'flex-start': 'center'}`, fontWeight: 400, fontSize: "15px", fontFamily: "Lato", backgroundColor: "white", color: "#375280", borderRadius: "0px", width: "max-content", marginLeft: '32%' },
  tryItOutImg: { display: "flex", alignItems: 'center', fontWeight: 400, fontSize: "15px", fontFamily: "Lato", backgroundColor: "white", color: "#375280", borderRadius: "0px", width: "max-content", marginRight: '32%' },
  likeimg: { display: "flex", top: 0, marginLeft: '-20%', fontWeight: 400, justifyContent: 'center', fontSize: "15px", backgroundColor: "white", color: "#375280", borderRadius: "50%", height: "40px", width: "40px" },
  shreImg: { display: "flex", top: 0, marginLeft: '-82%', fontWeight: 400, justifyContent: 'center', fontSize: "15px", backgroundColor: "white", color: "#375280", borderRadius: "50%", height: "40px", width: "40px" },
  legendBox: { display: "flex", top: 40, width: "100px", height: "40px", backgroundColor: "transparent", marginLeft:'36%' },
  cart: { display: "flex", justifyContent: "center", fontWeight: 700, fontSize: "32px", color: "#375280", fontFamily: "Lato", marginBottom: "15px" },
  cartLine: { margin: "0 auto", width: "78px", height: "4px", background: "#CCBEB1", marginBottom: "40px" },
  acountMrpproductd: { fontFamily: "Lato", display: "flex", alignItems: "center", flexDirection: "column-reverse" as "column-reverse", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
  actualPrice: { fontFamily: "Lato", color: '#375280', fontSize: '18px', fontWeight: 700},
  productDescriptionproductd: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productNameproductd: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productValueproductd: { height: '50px', lineHeight: '50px', overflow: 'hidden', display: "flex" as "flex", flexDirection: "column" as "column", gap: 6, textOverflow: 'ellipsis', flex: '1 2 auto' },
  productDataproductd: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' },
  productFavoriteproductd: { color: "#375280", marginRight: '20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productDiscountproductd: { color: "#375280", fontSize: '12px', padding: '2px 5px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
  productIconproductd: { top: '20px', left: '18px', display: "flex", justifyContent: 'space-between', right: '0' },
  iconBox: { display: "flex", gap: "20px", alignItems: "center" },
  iconBg: { display: "inline-flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", backgroundColor: "#F6F6F6", width: "50px", height: "50px" },
  insideDots: { width: 'calc(100% - 8px)', height: 'calc(100% - 8px)', borderRadius: "50%", top: "3px", left: "3px", },
  selectedDots: { display: 'inline-block', width: '30px', height: '30px', borderRadius: "50%", },
  buttonBye: {
    fontWeight: 700, color: "#fff", width: "314px", height: "52px", backgroundColor: '#CCBEB1', padding: "14px 28px 14px 28px", borderRadius: "2px", fontSize: "18px",
    '&:hover': { backgroundColor: "none" }, fontFamily: "Lato"
  },
  allTab: { textTransform: 'none', fontSize: "14px", fontFamily: "Lato", fontWeight: 700, color: "#375280", borderBottom: '1px solid gray' },
  mainTitle: { fontSize: '34px', color: "#375280", fontWeight: 800, fontFamily: "Lato" },
  subTitle: { fontWeight: 400, fontSize: '24px', color: "#375280", marginTop: "15px", fontFamily: "Lato" },
  priceContainer: { fontSize: '34px', color: "#375280", fontFamily: "Lato", marginTop: "15px", marginBottom: "35px", display: "flex", gap: 10, flexWrap: "wrap" as "wrap", flexDirection: "column" as "column"},
  priceOfferContainer: { fontSize: '34px', color: "#375280", fontFamily: "Lato", display: "flex", gap: 20, flexWrap: "wrap" as "wrap", alignItems: "center"},
  Price: { fontSize: '34px', color: "#375280", fontWeight: 700, fontFamily: "Lato" },
  size: { color: "#375280", fontSize: '18px', fontWeight: 700, fontFamily: "Lato" },
  selectedSize: { color: "#94A3B8", fontSize: '18px', fontWeight: 400, fontFamily: "Lato" },
  color: { fontSize: "18px", color: "#375280", fontWeight: 700, fontFamily: "Lato" },
  shipping: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 500 },
  loyaltyPoint: { marginBottom: "10px", color: "#375280", fontSize: "18px", fontFamily: "Lato" },
  badgeImg: { width: "20px", height: "20px", marginBottom: "-3px" }
};
const CustomCarousel = styled('div')({
  '& .carousel-root': {
    height: "100%",
    "& .carousel.carousel-slider":{
      height: "100%",
      "& .slider-wrapper.axis-horizontal": {
        height: "100%",
        "& .slider.animated": {
          height: "100%",
          "& .slide.selected": {
            height: "100%",
            "& .active-img":{
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center", 
              width: "100%",
              height: "100%"
            }
          }
        }
      }
    }
  },
  '& .carousel .slide .legend': {
    transition: 'all .5s ease-in-out',
    position: 'absolute',
    bottom: '40px',
    left: '50%',
    marginLeft: '-45%',
    width: '90%',
    borderRadius: '10px',
    padding: '10px',
    fontSize: '12px',
    textAlign: 'center',
    opacity: '1',
  },
  '& .carousel .slide img': {
    width: "100%",
    height: "100%",
  },
  '& .control-dots': {
    bottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  "& .zyler-try-on-cta": {
    display: "block",
    borderRadius: "2rem",
    border: "none",
    padding: "0.5rem 1rem",
    margin: 0,
    backgroundColor: "blue",
    cursor: "pointer",

  },

  '& .carousel .control-dots .dot': {
    backgroundColor: "#A4BCE5 !important",
    margin: '0 3px !important',
    width: "8px !important",
    height: '8px !important',
    boxShadow: 'none',
    opacity: 1
  },
  '& .carousel .control-dots .dot.selected': {
    margin: '0 3px !important',
    backgroundColor: "#375280 !important",
    width: "12px !important",
    height: '12px !important',
    backgroundSize: 'cover'
  },
  '& .legend': {
    left: 'auto',
    opacity: 1,
  },
  '& .carousel .control-prev .control-arrow': {
    backgroundColor: 'aquamarine',
  },
  // .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover
});
const ButtonAdd = styled(Button)({
  fontWeight: 700,
  color: "#375280",
  border: "2px solid #CCBEB1",
  width: "197px",
  height: "52px",
  padding: "10px 16px",
  borderRadius: "2px",
  textTransform: "none",
  fontSize: "18px",
  lineHeight: "20px",
  '&&.MuiButton-root': {
    '&:hover': {
      background: 'none',
    }
  },
  fontFamily: "Lato"
})
const AccordionDetailsData = styled(Accordion)({
  padding: 0,
  color: "#375280",
  borderBottom: '1px solid rgba(0, 0, 0, 0.12) !important',
  boxShadow: 'none !important',
  '&:before': {
    backgroundColor: 'transparent !important',
    borderTop: 'none !important',
  },
  '&.Mui-expanded': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12) !important',
  },
  fontFamily: "Lato",
})
// Customizable Area End
