import React, { Component } from "react";
import { IGetCartResponse } from "../BuyerStylishCartPayment/ProductCartDetailController";
import { Noimageavailable } from "../../../productdescription3/src/assets";
import i18n from "../../../../components/src/i18next/i18n";
import { styled, Box } from "@material-ui/core";
import { checkCondition } from "../../../../components/src/Seller/logOut";

interface Props {
  transPay: (transKey: string) => string;
  handlePlaceOrder: () => void;
  cartData: IGetCartResponse;
  currencySign: string;
}

interface S {}

class OrderSummary extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  multiplyAndFormat = (num1: number, num2: number): string => {
    const result = num1 * num2;
    return result.toFixed(2);
  };

  renderOrderSummary() {
    const { cartData, currencySign, transPay, handlePlaceOrder } = this.props;
    if (!cartData.cart_item) return <></>;

    const {
      product_quantity,
      primary_display_image,
      product_name,
      color,
      price_per_unit,
      shipping_cost,
      total_amount,
    } = cartData.cart_item.data.attributes;
    const id = cartData.cart_item.data.id;
    const subTotal = this.multiplyAndFormat(product_quantity, +price_per_unit);

    return (
      <Box>
        <Box>
          <OrderSummaryDetailContainer key={id}>
            <img
              src={
                checkCondition(primary_display_image === "",
                  Noimageavailable,
                primary_display_image) as string
              }
              className="order-product-img"
            />
            <Box className="order-product-detail">
              <p>
                {product_name}{" "}
                <span className="order-product-sub-detail">
                  x {product_quantity}
                </span>
              </p>
              <p className="order-product-color-detail">
                {transPay("Color")} :{" "}
                <span className="order-product-sub-detail">{color}</span>
              </p>
            </Box>
            <p className="order-product-price-detail">
              {currencySign} {subTotal}
            </p>
          </OrderSummaryDetailContainer>
          <hr className="summary-break-line" />
        </Box>
        <ShippingOrderContainer>
          <Box className="main-container">
            <SubTotalBox>
              <p>
                {transPay("Subtotal")}{" "}
                <span className="color-name">
                  ({product_quantity} {transPay("items")})
                </span>
              </p>
              <p className="font-size">
                {currencySign} {subTotal}
              </p>
            </SubTotalBox>
            {+shipping_cost > 0 && (
              <ShippingCostBox>
                <p className="margin-top">{transPay("Shipping Fee")}</p>
                <p className="margin-top">
                  {" "}
                  {currencySign} {parseFloat(shipping_cost).toFixed(2)}
                </p>
              </ShippingCostBox>
            )}
          </Box>
        </ShippingOrderContainer>
        <hr className="summary-break-line" />
        <ShippingOrderContainer>
          <ShippingContainerMainBox data-test-id="shipping-main-container" dir={i18n.dir()}>
            <Box className="total-container">
              <p>{transPay("Total")}</p>
              <p>
                {currencySign} {parseFloat(total_amount).toFixed(2)}
              </p>
            </Box>
          </ShippingContainerMainBox>
        </ShippingOrderContainer>
        <PlaceholderBtn
          data-test-id="placeOrderNavigation"
          onClick={() => handlePlaceOrder()}
        >
          {transPay("Place Order")}
        </PlaceholderBtn>
      </Box>
    );
  }

  render() {
    const { transPay } = this.props;
    return (
      <OrderSummaryMainContainer>
        <Box className="order-summary-title-container">
          <Box className="order-summary-title">{transPay("Order Summary")}</Box>
          <hr className="summary-break-line" />
          {this.renderOrderSummary()}
        </Box>
      </OrderSummaryMainContainer>
    );
  }
}

export default OrderSummary;

const ShippingOrderContainer = styled(Box)({
  marginBottom: "14px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  color: "#375280",
  "& .main-container": {
    fontFamily: "Lato , sans-serif",
    fontWeight: 600,
    flex: "1",
    fontSize: "18px",
    marginLeft: "10px",
  },
});

const OrderSummaryMainContainer = styled(Box)({
  margin: "auto",
  paddingInline: 20,
  "& .order-summary-title-container": {
    padding: "16px 12px 16px 16px",
    fontWeight: 700,
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: "28.8px",
    backgroundColor: "#FFFFFF",
    fontFamily: "Lato , sans-serif",
    fontSize: "24px",
    "& .order-summary-title": {
      color: "#375280",
      gap: "4px",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
      marginBottom: "14px",
    },
    "& .summary-break-line": {
      color: "rgb(242, 240, 240)",
    },
  },
});

const OrderSummaryDetailContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "14px",
  cursor: "pointer",
  gap: "4px",
  color: "#375280",
  "& .order-product-img": {
    height: "63px",
    width: "63px",
    borderRadius: "4px",
  },
  "& .order-product-detail": {
    fontFamily: "Lato , sans-serif",
    fontWeight: 600,
    fontSize: "14px",
    flex: "1",
    marginLeft: "10px",
    "& .order-product-sub-detail": {
      color: "#94A3B8",
    },
    "& .order-product-color-detail": {
      marginTop: "-18px",
    },
  },
  "& .order-product-price-detail": {
    color: "#94A3B8",
    fontSize: "14px",
    marginLeft: "10px",
  },
});

const PlaceholderBtn = styled(Box)({
  color: "white",
  backgroundColor: "#CCBEB1",
  display: "flex",
  height: "64px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: 700,
  border: "1px solid #CCBEB1",
  borderRadius: "2px",
  cursor: "pointer",
  marginLeft: "10px",
  marginRight: "5px",
});

const ShippingCostBox = styled(Box)({
  justifyContent: "space-between",
  display: "flex",
  fontSize: "18px",
  "& .margin-top": {
    marginTop: "-5px",
  },
});

const SubTotalBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  "& .color-name": {
    color: "#94A3B8",
  },
  "& .font-size": {
    fontSize: "18px",
  },
});

const ShippingContainerMainBox = styled(Box)(
  ({ dir }: { dir: "rtl" | "ltr" }) => ({
    fontFamily: "Lato , sans-serif",
    fontWeight: 600,
    flex: "1",
    margin: checkCondition(
      dir === "ltr",
      "0px 0px 0px 10px",
      "0px 10px 0px 0px"
    ) as string,
    fontSize: "18px",
    "& .total-container": {
      justifyContent: "space-between",
      fontSize: "18px",
      display: "flex",
    },
  })
);
