import React from "react";

// Customizable Area Start
import AddShippingAddressManagementController, {
  Props,
} from "./AddShippingAddressManagementController";
import BuyerCartWrapper from "../components/BuyerCartWrapper";
import AddressForm from "../components/AddressForm";
import { Grid } from "@material-ui/core";
import CommonBackdrop from "../../../../components/src/BackDrop";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import OrderSummary from "../components/OrderSummary";
import { IShowAddress } from "./ShippingAddressDetailController";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class AddShippingAddressManagement extends AddShippingAddressManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  addressData: IShowAddress = {
    name: "",
    country_code: "+965",
    phone_number: "",
    contact_number: "",
    street: "",
    zipcode: "",
    area: "",
    block: "",
    city: "",
    house_or_building_number: "",
    floor: "",
    address_name: "",
    is_default: false,
    latitude: null,
    longitude: null,
    country_code_name: "KW",
  };

  // Customizable Area End
  render() {
    const {
      cartData,
      currencySign,
      isAlert,
      isBackDrop,
      alertMsg,
      alertType,
      countryCode,
    } = this.state;
    return (
      // Customizable Area Start
      <BuyerCartWrapper
        navigation={this.props.navigation}
        activeBreadCrumb="address"
        transPay={this.transPay}
        data-test-id="add-shipping-bread-crumbs"
      >
        <Grid container data-test-id="add-shipping-grid-container">
          <AddressForm
            transPay={this.transAddress}
            getNavigationAddAddress={() =>
              this.getNavigationToPath("ShippingAddressDetail")
            }
            data-test-id="add-shipping-form"
            handleSuccess={this.addAddressCalled}
            id=""
            addressData={this.addressData}
            countryFlags={countryCode}
          />

          <Grid item xs={12} md={4} data-test-id="add-shipping-grid-item">
            <OrderSummary
              transPay={this.transPay}
              cartData={cartData}
              data-test-id="order-summary"
              currencySign={currencySign}
              handlePlaceOrder={this.updateAddressToOrder}
            />
          </Grid>
        </Grid>
        <ReusableSnackbar
          open={isAlert}
          dataTestId="alertTestId"
          message={alertMsg}
          autoHideDuration={3000}
          onClose={this.onAlertSnackClose}
          severity={alertType}
        />
        <CommonBackdrop open={isBackDrop} />
      </BuyerCartWrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
