import React, { Component } from "react";
import { getGeolocationCoordinates } from "../../../../components/src/GeoLocation";
import {
  getLocationDetails,
  loadGoogleAPIs,
  LocationDetails,
} from "../../../../components/src/Googlelogin/googleMap.web";
import { GoogleMap, Marker } from "@react-google-maps/api";
import i18n from "../../../../components/src/i18next/i18n";
import { location as endLocationIcon } from "../../../addressmanagement/src/assets";
import { CustomTypography } from "../../../addressmanagement/src/EditAddress.web";
import {
  Grid,
  Box,
  Typography,
  TextField,
  styled,
  Switch,
  withStyles,
} from "@material-ui/core";
import CountrySelect, { ICountryResp } from "../../../../components/src/CountryDropDown.web";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CountrySuccess,
  FormError,
  FormErrorTouched,
} from "../../../customform/src/CreateSellerStoreController";
import {
  IAddUpdateAddress,
  IShowAddress,
} from "../BuyerStylishCartPayment/ShippingAddressDetailController";
import { group_error } from "../../../promocodes/src/assets";
import { checkCondition } from "../../../../components/src/Seller/logOut";

interface Props {
  transPay: (transKey: string) => string;
  getNavigationAddAddress: () => void;
  handleSuccess: (address: IAddUpdateAddress) => void;
  id: string;
  addressData: IShowAddress;
  countryFlags: CountrySuccess[];
  isEdit?: boolean;
}

interface S {
  mapsLoaded: boolean;
  mapVisible: boolean;
  selectedLocation: { lat: number; lng: number } | null;
  currentLocation: { lat: number; lng: number } | null;
  showAddressData: IShowAddress;
  latitude: number | null;
  longitude: number | null;
  userError: boolean;
  selectedCountry: {country_code: string, numeric_code: string}
}

class AddressForm extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mapVisible: false,
      selectedLocation: null,
      currentLocation: null,
      mapsLoaded: false,
      showAddressData: {} as IShowAddress,
      latitude: null,
      longitude: null,
      userError: false,
      selectedCountry: {country_code: "KW", numeric_code: "+965"},
    };
  }

  scrollToSection = (sectionIdName: string) => {
    const section = document.getElementById(sectionIdName);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  coordinationErrorMessage = () =>{
    const {userError } = this.state;

    return userError && (
      <NotSelectErrorBox id="coordination-error">
        <Box className="error-container">
          <img src={group_error} className="img-css" />
          <Box>
            <Box className="error-text">
              {"Please Select Location Using use my current Location"}
            </Box>
          </Box>
        </Box>
      </NotSelectErrorBox>
    );
  }

  handleCountryChange = (countryData: ICountryResp)=>{
    this.setState((prevState)=> ({...prevState, selectedCountry: {...prevState.selectedCountry, country_code: countryData.country_code, numeric_code: countryData.numeric_code }}))
  }

  renderLocationMapButton() {
    const { transPay } = this.props;
    return (
      <>
        <LocationBoxContainer
          dir={i18n.dir()}
          data-test-id="use-current-location"
          onClick={this.handleUseCurrentLocation}
        >
          <img src={endLocationIcon} alt="location icon" />
          <CustomTypography>
            {transPay("Use my current location")}
          </CustomTypography>
        </LocationBoxContainer>
        {this.coordinationErrorMessage()}
      </>
    );
  }

  renderSearchInput() {
    const { mapsLoaded, mapVisible } = this.state;
    const {transPay } = this.props;
    return (
      mapsLoaded && (
        <MapContainerBox dir={i18n.dir()} isMapVisible={mapVisible}>
          <input
            id="autocomplete"
            type="text"
            placeholder={transPay("Search for a place")}
            className="search-box"
          />
        </MapContainerBox>
      )
    );
  }

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = checkCondition(
      isError,
      errors[field] ?? "",
      ""
    ) as string;
const { transPay } = this.props;
    return { isError, helperText: transPay(helperText) };
  };

  handleMapClick = async (event: google.maps.MapMouseEvent, form: string) => {
    const locationDetails = await getLocationDetails(
      event.latLng.lat(),
      event.latLng.lng()
    );
    if (event.latLng) {
      this.setState((prevState) => ({
        selectedLocation: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        showAddressData: {
          ...prevState.showAddressData,
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
          street: locationDetails.formattedAddress,
          city: locationDetails.city,
          area: locationDetails.area,
          house_or_building_number: locationDetails.houseNumber,
          block: locationDetails.blockNumber,
          zipcode: locationDetails.postalCode,
        },
      }));
    }
  };

  renderGoogleMap() {
    const {mapsLoaded, mapVisible, currentLocation, selectedLocation}= this.state;
    return (
      mapsLoaded &&
      mapVisible &&
      currentLocation && (
        <GoogleMap
          id="editMap"
          data-test-id="editMapTest"
          mapContainerStyle={{ ...webStyle.mapContainerStyle }}
          zoom={14}
          center={currentLocation}
          onClick={(event: google.maps.MapMouseEvent) =>
            this.handleMapClick(event, "editMap")
          }
        >
          {selectedLocation && (
            <Marker position={selectedLocation} />
          )}
        </GoogleMap>
      )
    );
  }

  handleUseCurrentLocation = async () => {
    const { latitude, longitude } = this.state;
    getGeolocationCoordinates(
      async (coordinates) => {
        const currentLatLng = {
          lat: latitude ?? coordinates.latitude,
          lng: longitude ?? coordinates.longitude,
        };

        this.setState({
          currentLocation: currentLatLng,
          selectedLocation: currentLatLng,
          mapVisible: true,
          userError: false,
        });
        const {
          blockNumber,
          city,
          postalCode,
          area,
          houseNumber,
          formattedAddress,
        } = await getLocationDetails(currentLatLng.lat, currentLatLng.lng);

        this.setState((prevState)=>({
          ...prevState,
          currentLocation: currentLatLng,
          selectedLocation: currentLatLng,
          mapVisible: true,
          showAddressData: {
            ...prevState.showAddressData,
            house_or_building_number: houseNumber,
            latitude: currentLatLng.lat,
            zipcode: postalCode,
            area,
            longitude: currentLatLng.lng,
            block: blockNumber,
            city,
            street: formattedAddress,
          },
        }));
      },
      (error) => {
        console.error(error);
      }
    );
  };

  handleScriptLoadAddress = () => {
    this.setState({ mapsLoaded: true });

    const initAutocomplete = async (
      inputId: string,
      stateUpdates: (
        lat: number,
        lng: number,
        locationDetails: LocationDetails,
        prevState: Readonly<S>
      ) => Partial<S>
    ) => {
      const input = document.getElementById(inputId) as HTMLInputElement;

      if (input) {
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
          types: ["geocode"],
        });
        autocomplete.setFields(["geometry", "formatted_address"]);

        autocomplete.addListener("place_changed", async () => {
          const place = autocomplete.getPlace();

          if (place.geometry && place.geometry.location) {
            const { lat, lng } = place.geometry.location;
            const locationDetails = await getLocationDetails(lat(), lng());

            this.setState((prevState) => {
              const updates = stateUpdates(
                lat(),
                lng(),
                locationDetails,
                prevState
              );
              return {
                ...prevState,
                ...updates,
              };
            });
          } else {
            console.error("No geometry found for this place.");
          }
        });
      }
    };

    initAutocomplete(
      "autocomplete",
      (lat, lng, locationDetails, prevState) => ({
        selectedLocation: { lat, lng },
        currentLocation: { lat, lng },
        showAddressData: {
          ...prevState.showAddressData,
          latitude: lat,
          longitude: lng,
          street: locationDetails.formattedAddress,
          city: locationDetails.city,
          block: locationDetails.blockNumber,
          house_or_building_number: locationDetails.houseNumber,
          area: locationDetails.area,
          zipcode: locationDetails.postalCode,
        },
      })
    );
  };

  countryCodeData = (country_code:string)=>{
    let countryData: string = "";
    if (country_code) {
      countryData = this.getCountryByCode(country_code);
      return countryData
    }else {
      return "KW"
    }
  }

  getCountryByCode = (code: string): string => {
    const { countryFlags } = this.props;
    const country = countryFlags.find((country) => country.numeric_code === code);
    return checkCondition(country!==undefined, country?.country_code, "KW") as string;
  };

  async componentDidMount() {
    const { addressData } = this.props;
    loadGoogleAPIs(this.handleScriptLoadAddress);
    this.setState({
      showAddressData: addressData,
      selectedCountry: {country_code: addressData.country_code_name ? addressData.country_code_name : this.countryCodeData(addressData.country_code),numeric_code: addressData.country_code},
      selectedLocation:
        addressData?.latitude !== null &&
        addressData?.longitude !== null
          ? {
              lat: addressData.latitude,
              lng: addressData.longitude,
            }
          : null,
    });
  }

  handleAddressSuccess = (value: IShowAddress) => {
    const { selectedLocation, selectedCountry } = this.state;

    if (selectedLocation) {
      const { lat, lng } = selectedLocation;
      const { id, handleSuccess } = this.props;
      if (lat || lng) {
        const data: IAddUpdateAddress = {
          ...value,
          country_code: selectedCountry.numeric_code,
          country_code_name: selectedCountry.country_code,
          id: id,
          latitude: lat,
          longitude: lng,
        };
        handleSuccess(data);
      }
    } else {
      this.setState({ userError: true }, () =>
        this.scrollToSection("coordination-error")
      );
    }
  };

  render() {
    const { transPay, countryFlags, isEdit, getNavigationAddAddress } = this.props;
    const { selectedCountry, showAddressData } = this.state;
    return (
      <MainContainerGrid item xs={12} md={8} dir={i18n.dir()}>
        <MainFormBox>
          <Box>
            <Box className="address-container">
              <Box className="address-title">
                {transPay(
                  `${
                    checkCondition(isEdit === true, "Edit", "Add") as string
                  } Address`
                )}
              </Box>

              <Box className="current-address-loc-btn">
                {this.renderLocationMapButton()}
                {this.renderSearchInput()}
                {this.renderGoogleMap()}
              </Box>
              <Formik
                data-test-id="address-form"
                initialValues={showAddressData}
                validationSchema={validationSchema}
                onSubmit={this.handleAddressSuccess}
                enableReinitialize
              >
                {({
                  getFieldProps,
                  setFieldValue,
                  errors,
                  touched,
                  values,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <p className="address-title-field">
                          {transPay("Full Name")}
                          <span className="address-title-field-required">
                            *
                          </span>
                        </p>
                        <CustomTextField
                          {...getFieldProps("name")}
                          id="name"
                          key="name"
                          data-test-id="memberId"
                          fullWidth
                          className="input-field"
                          variant="outlined"
                          error={
                            this.getErrorAndHelperText(
                              "name",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          inputProps={{ maxLength: 120}}
                          helperText={
                            this.getErrorAndHelperText(
                              "name",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} id="grid-item-phone">
                        <MainGridContainer container id="grid-container-phone">
                          <Grid item className="grid-item">
                            <Box>
                              <Typography
                                data-test-id="countryId"
                                className={"inputHeading"}
                                id="country-phone-id"
                              >
                                {transPay("Phone Number")}
                                <span className="address-title-field-required">
                                  *
                                </span>
                              </Typography>
                              <Box className={"phoneInput"} id="box-phone">
                                <CustomDropDownBox>
                                  <CountrySelect
                                    id="country_code"
                                    key="country_code"
                                    name="country_code"
                                    countryCode={countryFlags}
                                    value={selectedCountry.country_code} onChange={this.handleCountryChange}
                                    placeholder="Select Phone Code"
                                    isBorder
                                  />
                                </CustomDropDownBox>
                                <CustomTextField
                                  {...getFieldProps("phone_number")}
                                  id="phone_number"
                                  key="phone_number"
                                  data-test-id="phoneNumberTextFiled"
                                  variant="outlined"
                                  className="input-field"
                                  placeholder={transPay("Enter Contact Number")}
                                  inputProps={{ maxLength: 12 }}
                                  fullWidth
                                  error={
                                    this.getErrorAndHelperText(
                                      "phone_number",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).isError
                                  }
                                  helperText={
                                    this.getErrorAndHelperText(
                                      "phone_number",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).helperText
                                  }
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </MainGridContainer>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className="address-title-field">
                          {transPay("Street Name")}
                          <span className="address-title-field-required">
                            *
                          </span>
                        </p>
                        <CustomTextField
                          {...getFieldProps("street")}
                          id="street"
                          key="street"
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          className="input-field"
                          inputProps={{ maxLength: 120}}
                          error={
                            this.getErrorAndHelperText(
                              "street",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "street",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} id="grid-item-house-building">
                        <p
                          className="address-title-field"
                          id="p-house-building"
                        >
                          {transPay("House / Building Number")}
                          <span className="address-title-field-required">
                            *
                          </span>
                        </p>
                        <CustomTextField
                          {...getFieldProps("house_or_building_number")}
                          data-test-id="memberId"
                          fullWidth
                          key="house_or_building_number"
                          id="house_or_building_number"
                          variant="outlined"
                          className="input-field"
                          inputProps={{ maxLength: 40}}
                          error={
                            this.getErrorAndHelperText(
                              "house_or_building_number",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "house_or_building_number",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} id="gird-item-block">
                        <p id="p-block" className="address-title-field">
                          {transPay("Block")}
                          <span className="address-title-field-required">
                            *
                          </span>
                        </p>
                        <CustomTextField
                          {...getFieldProps("block")}
                          data-test-id="memberId"
                          key="block"
                          id="block"
                          fullWidth
                          className="input-field"
                          variant="outlined"
                          placeholder={transPay("Enter Block")}
                          inputProps={{ maxLength: 40}}
                          error={
                            this.getErrorAndHelperText(
                              "block",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "block",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} id="grid-item-area">
                        <p className="address-title-field" id="p-area">
                          {transPay("Area")}
                          <span className="address-title-field-required">
                            *
                          </span>
                        </p>
                        <CustomTextField
                          {...getFieldProps("area")}
                          data-test-id="memberId"
                          id="area"
                          key="area"
                          fullWidth
                          variant="outlined"
                          className="input-field"
                          inputProps={{ maxLength: 60}}
                          error={
                            this.getErrorAndHelperText(
                              "area",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "area",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                      <Grid item xs={12} id="grid-item-city" sm={6}>
                        <p className="address-title-field" id="p-city">
                          {transPay("City")}
                          <span className="address-title-field-required">
                            *
                          </span>
                        </p>
                        <CustomTextField
                          {...getFieldProps("city")}
                          id="city"
                          key="city"
                          data-test-id="cityTestId"
                          fullWidth
                          variant="outlined"
                          inputProps={{ maxLength: 80}}
                          className="input-field"
                          error={
                            this.getErrorAndHelperText(
                              "city",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "city",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                      <Grid item id="grid-item-zip-code" xs={12} sm={6}>
                        <p className="address-title-field" id="p-zip-code">
                          {transPay("Zip Code")}
                        </p>
                        <CustomTextField
                          {...getFieldProps("zipcode")}
                          data-test-id="memberId"
                          id="zip-code"
                          key="zip-code"
                          fullWidth
                          variant="outlined"
                          className="input-field"
                          inputProps={{ maxLength: 5}}
                          error={
                            this.getErrorAndHelperText(
                              "zipcode",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "zipcode",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                      <Grid item xs={12} id="grid-item-address-name" sm={6}>
                        <p id="p-address-name" className="address-title-field">
                          {transPay("Address Name")}
                          <span className="address-title-field-required">
                            *
                          </span>
                        </p>
                        <CustomTextField
                          {...getFieldProps("address_name")}
                          data-test-id="memberId"
                          id="address-name"
                          key="address-name"
                          fullWidth
                          className="input-field"
                          inputProps={{ maxLength: 120}}
                          variant="outlined"
                          error={
                            this.getErrorAndHelperText(
                              "address_name",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "address_name",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </Grid>
                    </GridContainer>
                    <DefaultBoxContainer>
                      <GreenSwitch
                        checked={values.is_default}
                        onChange={(e) =>
                          setFieldValue("is_default", e.target.checked)
                        }
                        inputProps={{ "aria-label": "toggle switch" }}
                        id="default-switch"
                      />
                      <p id="p-default-switch">
                        {transPay("Make it default delivery address")}
                      </p>
                    </DefaultBoxContainer>

                    <FormBoxBtnContainer>
                      <Box
                        className="btn-css"
                        onClick={getNavigationAddAddress}
                      >
                        {transPay("Cancel")}
                      </Box>
                      <button
                        className="btn-css save-btn"
                        type="submit"
                        data-test-id="handleSubmit"
                      >
                        {transPay("Save")}
                      </button>
                    </FormBoxBtnContainer>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </MainFormBox>
      </MainContainerGrid>
    );
  }
}

export default AddressForm;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Full Name is required"),
  phone_number: Yup.string()
    .matches(/^\d+$/, "Phone number must be numeric")
    .min(7, "Phone number is too short")
    .max(12, "Phone number is too long")
    .required("Phone Number is required"),
  street: Yup.string().required("Street Name is required"),
  house_or_building_number: Yup.string().required(
    "House / Building Number is required"
  ),
  block: Yup.string().required("Block is required"),
  area: Yup.string().required("Area is required"),
  city: Yup.string().required("City is required"),
  zipcode: Yup.string().nullable().matches(/^\d+$/, "Zipcode must be numeric"),
  address_name: Yup.string().required("Address Name is required"),
});

const NotSelectErrorBox = styled(Box)({
  backgroundColor: "#FEE2E24D",
  border: "1px solid #DC26264D",
  marginTop: "70px",
  padding: "12px 8px 12px 16px",
  "& .error-container": {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    gap: "10px",
    "& .img-css": {
      width: "27px",
    },
    "& .error-text": {
      color: "#DC2626",
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 600,
    },
  },
});

const MainContainerGrid = styled(Grid)({
  paddingInline: 20,
});

const CustomTextField = styled(TextField)({
  "& .MuiTextField-root": {
    "&:hover": {
      border: "1px solid #F0F0F0 !important",
    },
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error": {
    border: "1px solid #f44336",
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      color: "#f44336",
    },
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F0F0F0",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F0F0F0",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F0F0F0",
    borderRadius: "2px",
  },
  "& .MuiFormHelperText-contained": {
    margin: "0 !important",
    display: "flex",
  },
  "& .MuiInputBase-input": {
    color: "#375280",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F0F0F0",
  },
});

const MainGridContainer = styled(Grid)({
  justifyContent: "center",
  display: "flex",
  "& .grid-item": {
    width: "100%",
  },
  "& .mainCotainer": {
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    lineHeight: "26px",
    fontSize: "24px",
    fontStyle: "normal",
    alignSelf: "flex-start",
    fontWeight: 700,
    textOverflow: "ellipsis",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    marginBottom: "40px",
  },

  "& .inputHeading": {
    color: "#375280",
    marginBottom: "10px",
    fontStyle: "normal",
    marginTop: "20px",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& .phoneInput": {
    width: "100%",
    height: "56px",
    gap: "15px",
    display: "flex",
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    textOverflow: "ellipsis",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
  },
});

const FormBoxBtnContainer = styled(Box)({
  display: "flex",
  paddingInline: 10,
  alignItems: "center",
  gap: 22,
  "& .btn-css": {
    color: "#375280",
    fontWeight: 700,
    fontSize: "18px",
    border: "1px solid #CCBEB1",
    alignItems: "center",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "white",
    marginLeft: "10px",
    height: "64px",
    marginRight: "5px",
    width: "50%",
  },
  "& .save-btn": {
    backgroundColor: "#CCBEB1",
    color: "white",
  },
});

const GridContainer = styled(Grid)({
  width: "100%",
  margin: 0,
  "& .address-title-field": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#375280",
    fontFamily: "Lato , sans-serif",
  },
  "& .address-title-field-required": {
    color: "#375280",
  },
});

const MainFormBox = styled(Box)({
  backgroundColor: "#F8F8F8",
  "& .address-container": {
    backgroundColor: "#FFFFFF",
    lineHeight: "26px",
    marginBottom: "24px",
    padding: "16px 12px 16px 16px",
    boxShadow: "0px 2px 8px 0px #00000014",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
    "& .address-title": {
      fontSize: "20px",
      color: "#375280",
      marginBottom: "22px",
      marginLeft: "10px",
      fontFamily: "Lato , sans-serif",
    },
    "& .current-address-loc-btn": {
      position: "relative",
    },
  },
});

const webStyle = {
  mapContainerStyle: {
    width: "100%",
    height: "400px",
  },
};

const DefaultBoxContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  color: "#375280",
  fontSize: "16px",
  fontWeight: 500,
});

const LocationBoxContainer = styled(Box)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  gap: "8px",
  marginBottom: "22px",
  alignItems: "center",
  borderRadius: "2px",
  display: "flex",
  padding: "19px 35px",
  backgroundColor: "#CCBEB1",
  flexDirection: checkCondition(dir === "ltr", "row", "row-reverse") as
    | "row"
    | "row-reverse",
  marginLeft: "10px",
  cursor: "pointer",
  width: 184,
}));

const MapContainerBox = styled(Box)(
  ({ dir, isMapVisible }: { dir: "rtl" | "ltr"; isMapVisible: boolean }) => ({
    margin: checkCondition(
      dir === "ltr",
      "0px 10px 20px 10px",
      "0px 0px 20px 10px"
    ) as string,
    "& .search-box": {
      width: "254px",
      padding: "10px",
      borderRadius: "4px",
      backgroundColor: "white",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
      display: checkCondition(isMapVisible, "block", "none") as
        | "block"
        | "none",
    },
  })
);

const GreenSwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + $track": {
        height: 17,
        border: "none",
        opacity: 1,
        backgroundColor: "#CCBEB1",
      },
    },
    "&$focusVisible $thumb": {
      border: "6px solid #fff",
      color: "#4cd964",
    },
  },
  thumb: {
    width: 19,
    height: 21,
  },
  track: {
    borderRadius: 16 / 2,
    border: "1px solid #E5E5EA",
    opacity: 1,
    backgroundColor: "#E5E5EA",
    transition: "background-color 0.3s",
  },
  checked: {},
  focusVisible: {},
})(Switch);

const CustomDropDownBox = styled(Box)({
  width: "180px",
  cursor: "pointer",
  height: "100%",
  position: "relative",
});