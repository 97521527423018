import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import ShippingAddressDetailController, {
  Props,
} from "./ShippingAddressDetailController";
import BuyerCartWrapper from "../components/BuyerCartWrapper";
import i18n from "../../../../components/src/i18next/i18n";
import {
  plus,
} from "../../../customisableuserprofiles2/src/assets";
import CommonBackdrop from "../../../../components/src/BackDrop";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import OrderSummary from "../components/OrderSummary";
import AddressDropDown from "../components/AddressDropDown";
import { checkCondition } from "../../../../components/src/Seller/logOut";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ShippingAddressDetail extends ShippingAddressDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderAddressList = () => {
    const { addressData, selectedAddressId, openDropdownId } = this.state; 
    return (
      <AddressListBox>
        <Box>
          {addressData?.length > 0 ? (
            addressData?.map((address, index) => (
              <Box key={index} className="address-data-container">
                 <Box
                 className="address-data-box"
                >
                  <Box className="address-data-box-top">
                    <p
                    className="address-data-box-top-typo1"
                    >
                      {this.transPay(address?.attributes?.address_name)}
                    </p>
                    <p
                    className="address-data-box-top-typo2"
                    >
                      {address?.attributes?.is_default ? this.transPay("Default") : ""}
                    </p>
                  </Box>
                   <Box className="address-data-box-top">
                    <GreenCheckbox
                      checked={selectedAddressId === address?.id}
                      onChange={() => this.handleCheckboxChange(address?.id)}
                      data-test-id="checkBox"
                    />
                    <AddressDropDown data-test-id="address-dropdown" openDropdownId={openDropdownId} transPay={this.transPay} addressId={address.id} isDefault={address.attributes.is_default} toggleDropdown1={this.toggleDropdown1} handleOpenDeleteDialog={this.handleOpenDeleteDialog} 
                    getEditNavigationToPath={this.getEditNavigationToPath} handleMakeDefault={this.handleMakeDefault} />
                  </Box>
                </Box>
  
                <AddressTypoBox>
                  <p>{address.attributes.name}</p>
                  <Box className="phone-no-box">
                    <p>{address.attributes.country_code}</p>
                    <p>{address.attributes.phone_number}</p>
                  </Box>
                </AddressTypoBox>
                <Box
                  className="address-typo"
                >
                  {address?.attributes?.street},{" "}
                  {address?.attributes?.block},{" "}
                  {address?.attributes?.area}{" "}
                  <br />
                  {address?.attributes?.city} {address?.attributes?.zipcode ? ` - ${address?.attributes?.zipcode}`:""}
                </Box> 
              </Box>
            ))
          ) : (
            <p className="no-address-container">
              {this.transPay("No addresses found.")}
            </p>
          )}
        </Box>
      </AddressListBox>
    );
  };

  renderDeleteAddress = (isDeleteDialogOpen: boolean) => (
    <DialogContentStyledMain
      open={isDeleteDialogOpen}
      data-test-id="handleCloseDeleteDialog"
      onClose={this.handleCloseDeleteDialog}
      dir={i18n.dir()}
    >
      <DialogContentStyled>
        <Typography
          className="dialog-title"
        >
          {this.transPay("Are you sure you want to delete this address?")}
        </Typography>
      </DialogContentStyled>
      <DialogActions
        className="delete-dialog-action"
      >
        <Button
          onClick={this.handleCloseDeleteDialog}
          className="no-btn"
        >
          {this.transPay("No")}
        </Button>
        <Button
          onClick={this.handleConfirmDelete}
          data-test-id="handleConfirmDelete"
          className="yes-btn"
        >
          {this.transPay("Yes")}
        </Button>
      </DialogActions>
    </DialogContentStyledMain>
  );
  // Customizable Area End
  render() {
    const isMobile = window.innerWidth <= 768;
    const containerPadding = checkCondition(isMobile, "1px", "40px") as string;
    const {
      isDeleteDialogOpen,
      isAlert,
      alertMsg,
      alertType,
      isBackDrop,
      cartData,
      currencySign,
      isAddressBackDrop
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BuyerCartWrapper
          navigation={this.props.navigation}
          activeBreadCrumb="address"
          transPay={this.transPay}
        >
          <ShippingAddressMainBox
          data-test-id="shipping-address-main-box"
          containerPadding={containerPadding}
          >
            <Grid className="grid-container-margin" container dir={i18n.dir()}>
              <Grid item xs={12} md={8} className="grid-item-padding">
                <Box
                className="add-address-container"
                >
                  <Box
                    onClick={() =>
                      this.getNavigationToPath("AddShippingAddressManagement")
                    }
                    id="add-address-id"
                    data-test-id="getNavigationMessage"
                    className="add-address-btn"
                  >
                    <img src={plus}/>
                    {this.transPay("Add Address")}
                  </Box>
                </Box>
                {this.renderAddressList()}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className="grid-item-margin"
              >
                <OrderSummary data-test-id="order-summary" transPay={this.transPay} cartData={cartData} currencySign={currencySign} handlePlaceOrder={this.handlePlaceOrder} />
              </Grid>
            </Grid>
          </ShippingAddressMainBox>
        </BuyerCartWrapper>
        {this.renderDeleteAddress(isDeleteDialogOpen)}
        <ReusableSnackbar
          open={isAlert}
          message={alertMsg}
          onClose={this.onAlertSnackClose}
          severity={alertType}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
        <CommonBackdrop open={isBackDrop || isAddressBackDrop} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const GreenCheckbox = styled(Checkbox)({
  color: "#CCBEB1",
  "&.Mui-checked": {
    color: "#CCBEB1",
  },

  "&.Mui-checked + .MuiIconButton-label": {
    backgroundColor: "#CCBEB1",
  },
});
const DialogContentStyled = styled(DialogContent)({
  "&.MuiDialogContent-root:first-child": {
    paddingTop: "30px",
    textAlign: "center",
  },
  "& .dialog-title":{
    width: "300px",
    fontSize: "18px",
    color: "#375280",
    fontWeight: 600,
    marginLeft: "10px",
    fontFamily: "Lato",
  }
});

const DialogContentStyledMain = styled(Dialog)(
  ({ dir }: { dir: "rtl" | "ltr" }) => ({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "450px",
  },
  "& .MuiPaper-rounded": {
    borderRadius: "12px",
  },
  "& .delete-dialog-action":{
    display: "flex",
    gap: "10px",
    flexDirection: checkCondition(dir === "ltr", "row", "row-reverse") as "row" | "row-reverse",
    marginBottom: "10px",
    "& .no-btn":{
      alignItems: "center",
      fontSize: "15px",
      justifyContent: "center",
      fontWeight: 500,
      display: "flex",
      color: "#375280",
      backgroundColor: "white",
      border: "1px solid #CCBEB1",
      height: "46px",
      borderRadius: "5px",
      width: "50%",
      padding: "10px 16px 10px 16px",
      cursor: "pointer",
    },
    "& .yes-btn":{
      alignItems: "center",
      fontSize: "15px",
      justifyContent: "center",
      fontWeight: 500,
      display: "flex",
      color: "white",
      backgroundColor: "#CCBEB1",
      border: "1px solid #CCBEB1",
      height: "46px",
      borderRadius: "5px",
      width: "50%",
      padding: "10px 16px 10px 16px",
      cursor: "pointer",
    }
  }
}));

const ShippingAddressMainBox = styled(Box)(
  ({ containerPadding }: { containerPadding: string }) => ({
    display: "flex",
    padding: containerPadding,
    backgroundColor: "#F8F8F8",
    "& .grid-container-margin":{
      marginBottom: "58px",
      "& .grid-item-padding":{
        paddingInline: 20,
        "& .add-address-container": {
          fontFamily: "Lato , sans-serif",
          fontWeight: 700,
          fontSize: "20px",
          boxShadow: "0px 2px 8px 0px #00000014",
          marginBottom: "24px",
          lineHeight: "26px",
          backgroundColor: "#FFFFFF",
          padding: "16px 12px 16px 16px",
          "& .add-address-btn": {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "4px",
            color: "#375280",
            "& img":{
              cursor: "pointer"
            }
          }
        }
      },
      "& .grid-item-margin":{
        backgroundColor: "#F8F8F8", 
        marginBottom: "58px"
      }
    }
}));

const AddressListBox = styled(Box)({
  backgroundColor: "#F8F8F8",
  "& .address-data-container":{
    fontFamily: "Lato , sans-serif",
    fontWeight: 700,
    fontSize: "20px",
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: "26px",
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
    "& .address-data-box":{
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "26px",
      color: "#94A3B8",
      display: "flex",
      alignItems: "center",
      gap: "17px",
      justifyContent: "space-between",
      fontFamily: "Lato, sans-serif",
      "& .address-data-box-top":{
        display: "flex", 
        alignItems: "center",
        "& .address-data-box-top-typo1":{
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px 10px",
        },
        "& .address-data-box-top-typo2":{
          backgroundColor: "white",
          width: "94px",
          height: "36px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }
      }
    }
  },
  "& .no-address-container":{
    textAlign: "center", 
    color: "#94A3B8",
    fontFamily: "Lato , sans-serif",
    fontSize: "20px",
  },
  "& .address-typo":{
    fontSize: "14px",
    fontWeight: 400,
    color: "#94A3B8",
  }
})

const AddressTypoBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "17px",
  fontSize: "16px",
  lineHeight: "26px",
  fontWeight: 700,
  color: "#375280",
  "& .phone-no-box":{
    display: "flex", 
    gap: "5px"
  }
})
// Customizable Area End
