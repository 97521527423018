import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../components/src/APICall";
import {
  CountrySuccess,
  ErrorMessage,
} from "../../../customform/src/CreateSellerStoreController";
import { IGetCartResponse } from "./ProductCartDetailController";
import { IAddAddressResp } from "./AddShippingAddressManagementController";
import { AddressData, IAddUpdateAddress } from "./ShippingAddressDetailController";
import i18n from "../../../../components/src/i18next/i18n";
// Customizable Area Start

export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  editAddressData: AddressData;
  currencySign: string;
  isAlert: boolean;
  alertType: "error" | "success";
  alertMsg: string;
  isBackDrop: boolean;
  cartData: IGetCartResponse;
  countryCode: CountrySuccess[];
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditShippingAddressManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  showAddressAPICallId: string = "";
  countryCodeAPICallId: string = "";
  getBuyerStylishCartAPICallId: string = "";
  updateAddressAPICallId: string = "";
  updateOrderAddressAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      editAddressData: {} as AddressData,
      currencySign: "$",
      isAlert: false,
      alertType: "error",
      alertMsg: "",
      isBackDrop: true,
      cartData: {} as IGetCartResponse,
      countryCode: [],
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonEditShipping = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallEditShippingId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJsonEditShipping && !responseJsonEditShipping.errors) {
        this.apiSuccessEditShippingCall(apiRequestCallEditShippingId, responseJsonEditShipping);
      } else if (responseJsonEditShipping && responseJsonEditShipping.errors) {
        this.apiFailureEditShippingCall(responseJsonEditShipping);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const currencySign = await getStorageData("userRole", true);
    
    if (currencySign.currencyLogo) {
      this.setState({ currencySign: currencySign.currencyLogo });
    }
    await this.getCountryCodeApi();
    await this.getBuyerCart();
    // Customizable Area End
  }

  getToken = async () => {
    return await getStorageData("auth-token");
  };

  apiSuccessEditShippingCall = async (
    apiRequestCallEditShippingId: string,
    responseJson: IGetCartResponse & CountrySuccess[] & IAddAddressResp & { message: string }
  ) => {
    switch (apiRequestCallEditShippingId) {
      case this.getBuyerStylishCartAPICallId: {
        this.getBuyerStylishCartResp(responseJson);
        break;
      }

      case this.updateOrderAddressAPICallId:{
        this.updateOrderEditAddressResp(responseJson);
        break;
      }

      case this.countryCodeAPICallId: {
        await this.countryCodeAPIResp(responseJson)
        break;
      }

      case this.showAddressAPICallId:{
        this.showAddressResp(responseJson)
        break;
      }

      case this.updateAddressAPICallId: {
        await this.updateAddressResp(responseJson)
        break;
      }

      default:
        break;
    }
  };

  getBuyerStylishCartResp = (responseJson: IGetCartResponse) =>{
    if (responseJson?.cart_item) {
      this.setState({ cartData: responseJson, isBackDrop: false });
    }
  }

  updateOrderEditAddressResp = (responseJson: { message: string }) =>{
    if(responseJson.message){
      this.setState({alertType: "success",
        alertMsg: responseJson.message,
        isAlert: true}, ()=> setTimeout(() => this.getNavigationToPath("BuyerStylishCartPaymentDetail")
      , 800))
    }
  }

  countryCodeAPIResp = async (responseJson: CountrySuccess[]) =>{
    if (responseJson) {
      const addressID = await getStorageData("selectedAddressID");

      this.setState((prevState) => ({
        ...prevState,
        countryCode: responseJson,
      }), async ()=> await this.showAddress(addressID));
    }
  }

  showAddressResp = (responseJson: IAddAddressResp)=>{
    if (responseJson.data) {
      const { attributes } = responseJson.data;
    
      let countryData: string = "";
      
      if (!attributes.country_code_name) {
        countryData = this.getCountryByCode(attributes.country_code);
      }
    
      this.setState((prevState) => ({
        ...prevState,
        editAddressData: {
          ...responseJson.data,
          attributes:{
            ...attributes,
            country_code_name: countryData ? countryData : attributes.country_code_name,
          }
        },
        isBackDrop: false,
      }));
    }
  }

  updateAddressResp = async (responseJson: IAddAddressResp)=>{
    if (responseJson.data) {
      await setStorageData("selectedAddressID", responseJson.data.id);
      this.setState(
        (prevState) => ({
          ...prevState,
          isAlert: true,
          alertMsg: responseJson.meta.message,
          alertType: "success",
        }),
        () => setTimeout(
            () => this.getNavigationToPath("ShippingAddressDetail"),
            800
          )
      );
    }
  }

  getCountryByCode = (code: string): string => {
    const country = this.state.countryCode.find((country) => country.numeric_code === code);
    return country ? country.country_code : "KW";
  };

  apiFailureEditShippingCall = (responseErrorEditShippingJson: ErrorMessage & { error?: string }) => {
    if (responseErrorEditShippingJson.errors && responseErrorEditShippingJson.errors.length > 0) {
      const errorEditShippingMessages = responseErrorEditShippingJson.errors.map((errorObj) => {
        return Object.entries(errorObj)
          .map(([key, message]) => `${key}: ${message}`)
          .join(", ");
      });

      const finalErrorMessage = errorEditShippingMessages.join("\n");

      this.handleEditShippingFailure(finalErrorMessage);
    } else if (responseErrorEditShippingJson.error) {
      this.handleEditShippingFailure(responseErrorEditShippingJson.error);
    } else {
      this.handleEditShippingFailure("An unknown error occurred.");
    }
  };

  handleEditShippingFailure = (message: string) => {
    this.setState({
      alertType: "error",
      isBackDrop: false,
      alertMsg: message,
      isAlert: true,
    });
  };

  showAddress = async (addressID: string) => {
    const token = await this.getToken();
    this.showAddressAPICallId = await apiCall({
      token: token,
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.addressEndPoint}/${addressID}`,
    });
  };

  getCountryCodeApi = async () => {
    this.countryCodeAPICallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.countryCodeEndPoint,
    });
  };

  getBuyerCart = async () => {
    const order_id = await getStorageData("stylish_buyer_cart_id");
    const orderToken = await this.getToken();
    this.getBuyerStylishCartAPICallId = await apiCall({
      token: orderToken,
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.buyerStylishCartGetEndPoint}${order_id}`,
    });
  };

  getNavigationToPath = (path: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  transPay = (payKey: string) => {
    return i18n.t(payKey, { ns: "shoppingCart" });
  };

  onAlertSnackClose = () => {
    this.setState({ isAlert: false });
  };

  transAddress = (payKey: string) => {
    return i18n.t(payKey, { ns: "address" });
  };

  updateAddressCalled = async (address: IAddUpdateAddress) => {
    this.setState({ isBackDrop: true });
    const token = await this.getToken();
    const {
      name,
      area,
      block,
      street,
      house_or_building_number,
      zipcode,
      city,
      country_code,
      phone_number,
      latitude,
      longitude,
      address_name,
      is_default,
      country_code_name,
      id
    } = address;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("area", area);
    formData.append("block", block);
    formData.append("street", street);
    formData.append("house_or_building_number", house_or_building_number);
    formData.append("zipcode", zipcode);
    formData.append("city", city?.toString() || "");
    formData.append("country_code", country_code);
    formData.append("country_code_name", country_code_name || "KW");
    formData.append("phone_number", phone_number);
    formData.append("latitude", latitude ? String(latitude) : "");
    formData.append("longitude", longitude ? String(longitude) : "");
    formData.append("address_name", address_name);
    formData.append("is_default", is_default.toString());
    this.updateAddressAPICallId = await apiCall({
      token: token,
      body: formData,
      isJsonStringify: false,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.addressEndPoint}/${id}`,
    });
  };

  updateAddressToOrder = async () =>{
    const order_id = await getStorageData("stylish_buyer_cart_id");
    const addressID = await getStorageData("selectedAddressID")
    const token = await this.getToken();
    this.updateOrderAddressAPICallId = await apiCall({
      token: token,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.addAddressToOrderEndPoint}${order_id}&address_id=${addressID}`,
    });
    this.setState({isBackDrop: true})
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
