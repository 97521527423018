Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchatbot2";
exports.labelBodyText = "cfchatbot2 Body";
exports.retrieveChargeEndPoint = "/bx_block_tappaymentsintegration/tappayment/retrieve_order_request_charge"
exports.getCartEndPoint = "/bx_block_chat/show_cart"
exports.checkoutChatCartEndPoint = "/bx_block_chat/checkout"

exports.btnExampleTitle = "CLICK ME";

exports.buyerStylishCartGetEndPoint = "bx_block_chat/show_cart?id=";
exports.addressEndPoint = "bx_block_address/addresses";
exports.countryCodeEndPoint = "accounts/country_code_and_flags";

exports.apiContentType = 'application/json';
exports.deleteApiMethod = 'DELETE';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.addAddressToOrderEndPoint = "bx_block_chat/add_address_to_order_request?id="
// Customizable Area End