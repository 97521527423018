import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import i18n from "../../../../components/src/i18next/i18n";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../components/src/APICall";
import { ErrorMessage } from "../../../customform/src/CreateSellerStoreController";

type IStringNull = string | null;

interface IAddress {
  id: number;
  country: IStringNull;
  latitude: number;
  longitude: number;
  address_name: string;
  addressble_id: number;
  addressble_type: string;
  address_type: IStringNull;
  created_at: string;
  updated_at: string;
  first_name: IStringNull;
  last_name: IStringNull;
  number: IStringNull;
  street: string;
  zipcode: string;
  area: string;
  block: string;
  city: IStringNull;
  house_or_building_number: string;
  floor: IStringNull;
  apartment_number: IStringNull;
  name: string;
  contact_number: string;
  is_default: boolean;
  country_code: string;
  phone_number: string;
}
export interface IGetCartResponse {
  cart_item: {
    data: {
      id: string;
      type: string;
      attributes: {
        product_name: string;
        product_desc: string;
        status: string;
        gender: string;
        size: string;
        color: string;
        quantity: number;
        product_quantity: number;
        accept_through: IStringNull;
        accept_time: IStringNull;
        primary_display_image: string;
        primary_image: string;
        price_per_unit: string;
        shipping_cost: string;
        total_amount: string;
        sku: IStringNull;
        product_sourcing_request_id: IStringNull;
        stylist: string;
        buyer: string;
        estimated_arrival_time: string;
        payment_method: IStringNull;
        shipping_address: IAddress[];
      };
    };
  };
}
// Customizable Area Start

export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isBackDrop: boolean;
  cartData: IGetCartResponse;
  currencySign: string;
  severity: "error" | "success";
  message: string;
  isAlert: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductCartDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBuyerStylishCardAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isBackDrop: true,
      cartData: {} as IGetCartResponse,
      currencySign: "$",
      severity: "error",
      message: "",
      isAlert: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseProductCartJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseProductCartJson && !responseProductCartJson.errors) {
        this.apiSuccessProductCartCall(apiRequestCallId, responseProductCartJson);
      } else if (responseProductCartJson && responseProductCartJson.errors) {
        this.apiFailProductCartCall(responseProductCartJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiSuccessProductCartCall = async (
    apiRequestCallId: string,
    responseJson: IGetCartResponse
  ) => {
    if (apiRequestCallId === this.getBuyerStylishCardAPICallId) {
      if (responseJson.cart_item)
        this.setState({ cartData: responseJson, isBackDrop: false });
    }
  };

  apiFailProductCartCall = (responseErrorJson: ErrorMessage & { error: string }) => {
    if (responseErrorJson.errors[0].token) {
      this.handleFailure(responseErrorJson.errors[0].token);
    } else {
      this.handleFailure(responseErrorJson.error);
    }
  };

  handleFailure = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      severity: "error",
      isAlert: true,
      message: message,
      isBackDrop: false,
    }));
  };

  async componentDidMount() {
    // Customizable Area Start
    const currencySign = await getStorageData("userRole", true);
    if (currencySign.currencyLogo) {
      this.setState({ currencySign: currencySign.currencyLogo });
    }
    await this.getBuyerCart();
    // Customizable Area End
  }

  transPD = (pdKey: string) => {
    return i18n.t(pdKey, { ns: "shoppingCart" });
  };

  getBuyerCart = async () => {
    const order_id = await getStorageData("stylish_buyer_cart_id");
    const orderToken = await getStorageData("auth-token");
    this.getBuyerStylishCardAPICallId = await apiCall({
      token: orderToken,
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.buyerStylishCartGetEndPoint}${order_id}`,
    });
  };

  contioneShopping = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  proceedToCheckout = async () => {
    const userToken = await getStorageData("auth-token");
    if (!userToken) {
      await setStorageData("storedPath", document.location.href);
    }
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ShippingAddressDetail"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  multiplyAndFormat = (num1: number, num2: number): string => {
    const result = num1 * num2;
    return result.toFixed(2);
  };

  onAlertSnackClose = () => {
    this.setState({ isAlert: false });
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
