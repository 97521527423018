import React from "react";

// Customizable Area Start
import { Grid } from "@material-ui/core";
import EditShippingAddressManagementController, {
  Props,
} from "./EditShippingAddressManagementController";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import CommonBackdrop from "../../../../components/src/BackDrop";
import AddressForm from "../components/AddressForm";
import BuyerCartWrapper from "../components/BuyerCartWrapper";
import OrderSummary from "../components/OrderSummary";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class EditShippingAddressManagement extends EditShippingAddressManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const {
      cartData,
      currencySign,
      isAlert,
      isBackDrop,
      alertMsg,
      alertType,
      countryCode,
      editAddressData,
    } = this.state;

    return (
      // Customizable Area Start
      <BuyerCartWrapper
        navigation={this.props.navigation}
        activeBreadCrumb="address"
        data-test-id="edit-shipping-bread-crumbs"
        transPay={this.transPay}
      >
        <Grid container>
          {editAddressData.attributes && (
            <AddressForm
              isEdit
              transPay={this.transAddress}
              getNavigationAddAddress={() =>
                this.getNavigationToPath("ShippingAddressDetail")
              }
              data-test-id="edit-shipping-form"
              handleSuccess={this.updateAddressCalled}
              id={editAddressData.id}
              addressData={editAddressData.attributes}
              countryFlags={countryCode}
            />
          )}
          <Grid item xs={12} md={4}>
            <OrderSummary
              transPay={this.transPay}
              cartData={cartData}
              data-test-id="order-summary"
              currencySign={currencySign}
              handlePlaceOrder={this.updateAddressToOrder}
            />
          </Grid>
        </Grid>
        <ReusableSnackbar
          open={isAlert}
          message={alertMsg}
          onClose={this.onAlertSnackClose}
          severity={alertType}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
        <CommonBackdrop open={isBackDrop} />
      </BuyerCartWrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
