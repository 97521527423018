import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import i18n from "../../../../components/src/i18next/i18n";
import {
  CountrySuccess,
  ErrorMessage,
} from "../../../customform/src/CreateSellerStoreController";
import { IGetCartResponse } from "./ProductCartDetailController";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../components/src/APICall";
import {
  AddressData,
  IAddUpdateAddress,
} from "./ShippingAddressDetailController";
// Customizable Area Start
export interface IAddAddressResp {
  data: AddressData;
  meta: {
    message: string;
  };
}
export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currencySign: string;
  isAlert: boolean;
  alertType: "error" | "success";
  alertMsg: string;
  isBackDrop: boolean;
  cartData: IGetCartResponse;
  countryCode: CountrySuccess[];
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddShippingAddressManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBuyerStylishCartAPICallId: string = "";
  addAddressAPICallId: string = "";
  countryCodeAPICallId: string = "";
  updateOrderAddressAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      currencySign: "$",
      isAlert: false,
      alertType: "error",
      alertMsg: "",
      isBackDrop: true,
      cartData: {} as IGetCartResponse,
      countryCode: [],
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonAddShipping = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestAddShippingCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJsonAddShipping && !responseJsonAddShipping.errors) {
        this.apiSuccessAddShippingCall(apiRequestAddShippingCallId, responseJsonAddShipping);
      } else if (responseJsonAddShipping && responseJsonAddShipping.errors) {
        this.apiFailureAddShippingCall(responseJsonAddShipping);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const currencySign = await getStorageData("userRole", true);
    if (currencySign.currencyLogo) {
      this.setState({ currencySign: currencySign.currencyLogo });
    }
    await this.getBuyerCart();
    await this.getCountryCodeApi();
    // Customizable Area End
  }

  getBuyerCart = async () => {
    const order_id = await getStorageData("stylish_buyer_cart_id");
    const orderToken = await this.getToken();
    this.getBuyerStylishCartAPICallId = await apiCall({
      token: orderToken,
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.buyerStylishCartGetEndPoint}${order_id}`,
    });
  };

  getToken = async () => {
    return await getStorageData("auth-token");
  };

  apiSuccessAddShippingCall = async (
    apiRequestCallId: string,
    responseJsonAddShipping: IGetCartResponse & CountrySuccess[] & IAddAddressResp & { message: string }
  ) => {
    switch (apiRequestCallId) {
      case this.getBuyerStylishCartAPICallId: {
        this.getBuyerStylishCartResp(responseJsonAddShipping)
        break;
      }

      case this.updateOrderAddressAPICallId:{
        this.updateOrderAddressResp(responseJsonAddShipping)
        break;
      }

      case this.countryCodeAPICallId: {
        this.countryAPIResp(responseJsonAddShipping)
        break;
      }

      case this.addAddressAPICallId: {
        await this.addAddressAPIResp(responseJsonAddShipping)
        break;
      }

      default:
        break;
    }
  };

  getBuyerStylishCartResp = (responseJsonBuyerStylishCart: IGetCartResponse) =>{
    if (responseJsonBuyerStylishCart?.cart_item) {
      this.setState({ cartData: responseJsonBuyerStylishCart, isBackDrop: false });
    }
  }

  updateOrderAddressResp = (responseJsonUpdateOrderAddress: {message: string}) => {
    if(responseJsonUpdateOrderAddress.message){
      this.setState({alertType: "success",
        alertMsg: responseJsonUpdateOrderAddress.message,
        isAlert: true}, ()=> setTimeout(() => this.getNavigationToPath("BuyerStylishCartPaymentDetail")
      , 800))
    }
  }

  countryAPIResp = (responseJsonCountry: CountrySuccess[]) =>{
    if (responseJsonCountry) {
      this.setState((prevState) => ({
        ...prevState,
        countryCode: responseJsonCountry,
        isBackDrop: false,
      }));
    }
  }

  addAddressAPIResp = async (responseJsonAddAddress: IAddAddressResp) =>{
    if (responseJsonAddAddress.data && responseJsonAddAddress.meta) {
      await setStorageData("selectedAddressID", responseJsonAddAddress.data.id)
      this.setState(
        (prevState) => ({
          ...prevState,
          isAlert: true,
          alertMsg: responseJsonAddAddress.meta.message,
          alertType: "success",
        }),
        () => {
          setTimeout(
            () => this.getNavigationToPath("ShippingAddressDetail"),
            800
          );
        }
      );
    }
  }

  apiFailureAddShippingCall = (responseErrorAddShippingJson: ErrorMessage & { error?: string }) => {
    if (responseErrorAddShippingJson.errors && responseErrorAddShippingJson.errors.length > 0) {
      const errorAddShippingMessages = responseErrorAddShippingJson.errors.map((errorObj) => {
        return Object.entries(errorObj)
          .map(([key, message]) => `${key}: ${message}`)
          .join(", ");
      });

      const finalErrorMessage = errorAddShippingMessages.join("\n");

      this.handleAddShippingFailure(finalErrorMessage);
    } else if (responseErrorAddShippingJson.error) {
      this.handleAddShippingFailure(responseErrorAddShippingJson.error);
    } else {
      this.handleAddShippingFailure("An unknown error occurred.");
    }
  };

  handleAddShippingFailure = (message: string) => {
    this.setState({
      alertType: "error",
      isAlert: true,
      alertMsg: message,
      isBackDrop: false,
    });
  };

  transPay = (payKey: string) => {
    return i18n.t(payKey, { ns: "shoppingCart" });
  };

  transAddress = (payKey: string) => {
    return i18n.t(payKey, { ns: "address" });
  };

  onAlertSnackClose = () => {
    this.setState({ isAlert: false });
  };

  getNavigationToPath = (path: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  addAddressCalled = async (address: IAddUpdateAddress) => {
    this.setState({ isBackDrop: true });
    const token = await this.getToken();
    const {
      name,
      area,
      block,
      street,
      house_or_building_number,
      zipcode,
      city,
      country_code,
      phone_number,
      latitude,
      longitude,
      address_name,
      is_default,
      country_code_name
    } = address;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("area", area);
    formData.append("block", block);
    formData.append("street", street);
    formData.append("house_or_building_number", house_or_building_number);
    formData.append("zipcode", zipcode);
    formData.append("city", city?.toString() || "");
    formData.append("country_code", country_code);
    formData.append("phone_number", phone_number);
    formData.append("latitude", latitude ? String(latitude) : "");
    formData.append("longitude", longitude ? String(longitude) : "");
    formData.append("address_name", address_name);
    formData.append("country_code_name", country_code_name || "KW");
    formData.append("is_default", is_default.toString());

    this.addAddressAPICallId = await apiCall({
      token: token,
      body: formData,
      isJsonStringify: false,
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.addressEndPoint}`,
    });
  };

  getCountryCodeApi = async () => {
    this.countryCodeAPICallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.countryCodeEndPoint,
    });
  };

  updateAddressToOrder = async () =>{
    const order_id = await getStorageData("stylish_buyer_cart_id");
    const addressID = await getStorageData("selectedAddressID")
    const token = await this.getToken();
    this.updateOrderAddressAPICallId = await apiCall({
      token: token,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.addAddressToOrderEndPoint}${order_id}&address_id=${addressID}`,
    });
    this.setState({isBackDrop: true})
  }

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
