import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import AuthenticateUser from "../../../components/src/AuthenticateUser";
import React from "react";


// Customizable Area Start
import { addLatLngToUrl } from "../../../components/src/Googlelogin/googleMap.web";
interface CatalogueVariantSize {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
  };
};
interface INavigateTo {
  id: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}

interface Category {
  id: string | undefined;
  type: string;
  subSubData: SubCategory[] | undefined
  attributes: {
      id: number | undefined;
      name: string;
      status: string;
      created_at: string;
      updated_at: string;
      image: string;
  };
  subSubSubData?: SubCategory[]
}

interface SubCategory {
  id: string | number;
  type: string;
  subSubSubData?: SubsubsubCategory[]
  attributes: {
      id: number;
      name: string;
      status: string;
      created_at: string;
      updated_at: string;
      image: string;
  };
}
interface SubsubsubCategory {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      status: string;
      created_at: string;
      updated_at: string;
      image: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  dummySizes: CatalogueVariantSize[],
  alertMsg: string;
  circularProgress: boolean;
  buyerStylish: boolean;
  priceError: string;
  advancedSearchData: {
    id: string;
    attributes: {
      primary_image: string;
      is_wishlist: boolean;
      name: string;
      description: string;
      primary_price: string;
      primary_main_price: string;
      primary_discounted_percentage: number;
      primary_store_name: string;
    }
  }[];
  categoriesData: Category[];
  page: number;
  perPage: number;
  search: string;
  paginateScroll: boolean;
  dummySizesPush: number[];
  filterColor: CatalogueVariantSize[];
  sortedCategoryLoading: boolean;
  gender: string;
  subCategoryLoading: boolean;
  sort: string;
  filters: {
    minPrice: number,
    maxPrice: number,
    sizes: number[],
    colors: number[],
    sort: string,
    category: string
  };
  value: number[];
  colorePush: number[];
  storesData: {
    id: string;
    attributes: {
      store_name: string 
    }
  }[];
  storeChecked: boolean;
  storeCheck: string[];
  buyerStorePage: number;
  buyerStoreLoading: boolean;
  favouriteLoading: boolean;
  currencySign: string;
  modalOpen: boolean;
  locationP: {lat: number, lng: number} | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSearchdataApiCallID: string = "";
  getSearchdataScrollApiCallID: string = "";
  deleteFavouriteApiCalledId: string = "";
  postFavouriteApiCalledId: string = "";
  getSizeApiCallID:string = "";
  getColorApiCallID: string = "";
  getBuyerStoreAPICallID: string = "";
  getCategoryCallId: string = "";
  timeoutId: NodeJS.Timeout|null = null;
  observerP: MutationObserver | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      locationP: null,
      token: "",
      isAlert: false,
      alertType: 'success',
      favouriteLoading: false,
      value: [0, 0],
      alertMsg: '',
      circularProgress: false,
      page: 1,
      sort: "",
      perPage: 10,
      search: "",
      categoriesData: [],
      advancedSearchData: [],
      paginateScroll: true,
      dummySizes: [],
      buyerStylish: false,
      dummySizesPush: [],
      priceError:"",
      filterColor: [],
      sortedCategoryLoading: false,
      gender: '',
      storeCheck: [],
      subCategoryLoading: false,
      colorePush: [],
      currencySign: "$",
      filters: {
        minPrice: 0,
        maxPrice: 0,
        sizes: [],
        colors: [],
        sort: "",
        category: ""
      },
      buyerStorePage: 1,
      storesData: [],
      storeChecked: false,
      buyerStoreLoading: false,
      modalOpen: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("auth-token");
    const userDetails = await getStorageData("userRole", true);
    const stylishBuyer = await getStorageData("exploreBuyer", true);
    if(stylishBuyer) this.setState({buyerStylish: stylishBuyer})
    this.setState({
        token: token
    });
    const pLocation = await getStorageData("userLocation", true)
    if(pLocation){
        this.setState({ locationP: pLocation})
    }
    if(userDetails.currencyLogo){
      this.setState({
        currencySign: userDetails.currencyLogo
      })
    }
    this.getSearchApiCallID();
    this.getDummySizes();
    this.getColorApiCall();
    this.getBuyerStore();
    this.categoryTole()
    const dataBox = document.getElementById('scrollableDiv') as HTMLElement;
    dataBox.addEventListener('scroll', this.handleScroll);
    this.observerP = new MutationObserver(this.handleStorageChangeP);
    this.observerP.observe(document.body, { attributes: true });
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSearchdataApiCallID) {
        if(responseJson?.data){
            this.setState(prev => {
              return {
              advancedSearchData: responseJson.data,
               page: prev.page + 1,
               circularProgress: false
           }
        });       
        }else if(responseJson?.errors){
          this.setState({
            advancedSearchData: [], circularProgress: false,
            page: 1
          })
        }
        this.setState({
          circularProgress: false
        })
        }else if(apiRequestCallId === this.getSearchdataScrollApiCallID){
            if(responseJson?.data){
                const arrTrendList = [...this.state.advancedSearchData, ...responseJson.data];
                responseJson?.data?.length > 0 && this.setState({ currencySign: responseJson?.data[0]?.attributes?.currency_logo})
                this.setState(prev => {
                  return {
                    advancedSearchData: [...arrTrendList],
                   page: prev.page + 1,
                   circularProgress: false,
               }
            });       
            }else if(responseJson?.errors){
                this.setState({
                    circularProgress: false,
                    paginateScroll: false
                })
            } 
        }else if(apiRequestCallId === this.deleteFavouriteApiCalledId){
            if(responseJson.error){
                this.setState({
                  isAlert: true,
                  alertMsg: responseJson.error,
                  alertType: "error"
                })
              }else{
                this.setState({
                  isAlert: true,
                  alertMsg: `${configJSON.removeFavTitle}`,
                  alertType: "success"
                });
              }
              this.setState({
                favouriteLoading: false
              })
              this.getSearchApiCallID();
        }else {
          this.handleApiResponse(apiRequestCallId, message)
        }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    if(this.observerP){
      this.observerP.disconnect()
    }
  }

  handleApiResponse = (apiRequestCallId: string, message:Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
     if(apiRequestCallId === this.postFavouriteApiCalledId){
      if(responseJson?.error){
                this.setState({
                  isAlert: true,
                  alertType: "error",
                  alertMsg: responseJson.error
                });
              }else if(responseJson.errors?.length > 0 && responseJson.errors[0]?.token){
                this.setState({
                  isAlert: true,
                  alertType: "error",
                  alertMsg: `${responseJson.errors[0].token}`,
                });
              }else{
                  this.setState({
                    isAlert: true,
                    alertType: "success",
                    alertMsg: `${configJSON.addFavTitle}`,
                  });
                }
                this.setState({
                  favouriteLoading: false
                })
              this.getSearchApiCallID();
    }else if(apiRequestCallId === this.getSizeApiCallID){
      if(responseJson?.data){
        this.setState({
          dummySizes: responseJson?.data
        })
      }
    }else if(apiRequestCallId === this.getColorApiCallID){
      if(responseJson?.data){
        this.setState({
          filterColor: responseJson?.data
        })
      }
    }else if(apiRequestCallId === this.getBuyerStoreAPICallID){
      this.setState({
        storesData: responseJson?.data,
        buyerStoreLoading: false
      });
    }else{
      this.handleResponseApi(apiRequestCallId, message)
    }
  };

  handleResponseApi = (apiRequestCallId: string, message:Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getCategoryCallId){
      this.setState({
        categoriesData: responseJson?.data
      })
    }
  }

  handleCondition = (condition: any , trueSta: any, falseSta: any) => {
    return condition ? trueSta : falseSta 
  }

  getColorApiCall = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
    };
    const requestColorMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getColorApiCallID = requestColorMessageList.messageId;
    requestColorMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    requestColorMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.catalogues_variants_colors
    );
    requestColorMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestColorMessageList.id, requestColorMessageList);
};
  changeSearch = (value: string) => {
    this.setState({
        search: value,
        page: 1
    }, ()=>this.debounceTimeAPI())

  }

  debounceTimeAPI = () =>{
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.getSearchApiCallID();
    }, 800);
  }

  handleGenderChange = (event: React.ChangeEvent<{}>) => {
    const value = (event.target as HTMLInputElement).value;

    this.handleClearAll(value)
}
handleClearAll = (gender: string) => {
  this.setState({
    filters: {
      minPrice: 0,
      maxPrice: 0,
      sizes: [],
      colors: [],
      sort: "",
      category: ""
    },gender: gender, value: [0,0], colorePush: [], dummySizesPush: [], storeCheck: [], search: "", page: 1
  }, ()=> this.getSearchApiCallID())
}
handleSelectAll = (checkedValue: string) => {
  if(this.state.storeCheck.includes(checkedValue)){
    const filteredValue = this.state.storeCheck.filter(ele => ele !== checkedValue);
    this.setState({
      storeCheck : filteredValue, page: 1
    }, () => this.getSearchApiCallID())

  }else{
   this.setState((prevState) => ({
    storeCheck: [...prevState.storeCheck, checkedValue],
    page: 1
   }), () => this.getSearchApiCallID())
  }
}

handleAsFilterPriceChange = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  index: number
) => {
  const newData = { ...this.state.value };
  let parsedValue = event.target.value.replace(/[^0-9.]/g, "");

  if ((parsedValue.startsWith("0") && parsedValue.length === 1) || parsedValue.startsWith(".")) {
    parsedValue = "";
  }

  const numericValue = Math.min(+parsedValue, index === 1 ? 10000 : Number.MAX_SAFE_INTEGER);
  newData[index] = numericValue;

  if (index === 1 && numericValue === 0) {
    // Max value cannot be 0
    this.setState({
      ...this.state,
      value: [0, 0],
      filters: {
        ...this.state.filters,
        minPrice: 0,
        maxPrice: 0,
      },
      priceError: "",
    },() => this.debounceTimeAPI());
    
  } else if (newData[1] < newData[0]) {
    this.setState({
      ...this.state,
      value: newData,
      priceError: "Max price cannot be less than min price",
    });
  } else if (newData[0] === 0 && newData[1] === 0) {
    this.setState({
      ...this.state,
      value: newData,
      priceError: "",
    });
  } else {
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          minPrice: newData[0],
          maxPrice: newData[1],
        },
        value: newData,
        page: 1,
        priceError: "",
      },
      () => this.debounceTimeAPI()
    );
  }
};

categoryTole = async () => {
  this.setState({ sortedCategoryLoading: true })
  const token = await getStorageData("auth-token");
  const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token,
  };
  const requestCatMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCategoryCallId = requestCatMessageList.messageId;
  requestCatMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
  );
  requestCatMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categories
  );
  requestCatMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  runEngine.sendMessage(requestCatMessageList.id, requestCatMessageList);
};
handleColorClick = (colorId: number) => {
  let newlangColore: number[] = []
  if (this.state.colorePush.includes(colorId)) {
      newlangColore = this.state.colorePush.filter(value => value !== colorId)
  } else {
      newlangColore = [...this.state.colorePush, colorId]
  }
  this.setState({ colorePush: newlangColore, page: 1 }, () => this.getSearchApiCallID());
}

handleSortRadioChange = (value : string) => {
  this.setState((prevState) => ({
    filters: {
      ...prevState.filters,
      sort: value ,
    },
    page: 1
  }), () => this.getSearchApiCallID());
}

handleSizeClick = (sizeId: number) => {
  let newlangSize: number[] = []
  if (this.state.dummySizesPush.includes(sizeId)) {
      newlangSize = this.state.dummySizesPush.filter(value => value !== sizeId)
  } else {
      newlangSize = [...this.state.dummySizesPush, sizeId]
  }

  this.setState({ dummySizesPush: newlangSize, page: 1 }, ()=> this.getSearchApiCallID());
}

  getDummySizes = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
    };
    const requestSizeMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSizeApiCallID = requestSizeMessageList.messageId;
    requestSizeMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    requestSizeMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.catalogues_variants_sizes
    );
    requestSizeMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestSizeMessageList.id, requestSizeMessageList);
};

getBuyerStore = async () => {
  const token = await getStorageData("auth-token");
  this.setState({
    buyerStoreLoading: true
  })
  const {lat, lng} = this.state.locationP || {}
  const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
  };
  const requestStoreMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getBuyerStoreAPICallID = requestStoreMessageList.messageId;
  requestStoreMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
  );
  requestStoreMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      addLatLngToUrl(`${configJSON.getBuyerStoreApiCallID}?page=${this.state.buyerStorePage}&per_page=${16}`, lat, lng)
  );
  requestStoreMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  runEngine.sendMessage(requestStoreMessageList.id, requestStoreMessageList);
};

  handleFavouriteApi = async (event: {stopPropagation: () => void},favouriteId: string, fav: boolean) => {
    event?.stopPropagation()
    if(this.state.token === null){
      return this.setState({modalOpen: true})
    }
    this.setState({
      page: 1
    })
    if (fav) {
      this.handleDeleteWishlist(favouriteId);
    } else {
      this.handleAddWishlistApiCall(favouriteId);
    }
  }

  renderDialog = () => {
    return (
       <AuthenticateUser 
            auth={this.state.token} 
            title={'Log in to add to wishlist.'}
            description={"View list of wishlist"}
            btnText={"Log In or Sign up"}
            navigation={this.props.navigation}
            onClose={this.closeModal}
        />
    )
}

    closeModal = () => { 
      this.setState({
        modalOpen: false
      })
    }

  handleDeleteWishlist = async (favouriteId?: string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    this.setState({
      favouriteLoading: true
    })
    const requestDelFavMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFavouriteApiCalledId = requestDelFavMessage.messageId;
    requestDelFavMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    requestDelFavMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
    );
  
    requestDelFavMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    runEngine.sendMessage(requestDelFavMessage.id, requestDelFavMessage);
    return true;
  }

  handleAddWishlistApiCall = async (favouriteId?: string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    this.setState({
      favouriteLoading: true
    })
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const httpBody = {
      data: {
        favouriteable_id: favouriteId
      }
    };
  
    this.postFavouriteApiCalledId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postFavouriteApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;
    if(this.state.paginateScroll && (dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight)){
        this.getSearchApiPaginateScroll();
    };
  };

  getSearchApiPaginateScroll = async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };
    this.setState({
        circularProgress: true,
    })

    const currPSS = this.state.currencySign === "$" ? "dollar" : "dinar"
    const {lat, lng} = this.state.locationP || {}
    const endPointScroll = `${configJSON.getSearchApiEndPoint}?search=${this.state.search}&page=${this.state.page}&per_page=${this.state.perPage}${this.getFilterUri(this.state.filters)}&currency=${currPSS}`
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchdataScrollApiCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          addLatLngToUrl(endPointScroll, lat, lng)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }

    handleRangeSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
      const newValue = value as number[];
      this.setState((prevState) => ({
        filters: {...prevState.filters, 
          minPrice: newValue[0],
          maxPrice: newValue[1]
        },
        value: newValue, page: 1,
        priceError:""
      }),
      () => this.debounceTimeAPI())
    }

   getFilterUri = (filters: any) => {
          let query = "";
          const addFilter = (key: string, value?: string | string[] | null) => {
            if (value) {
              query += `&${key}=${value}`;
            }
          };
     const categoryID =  this.state.categoriesData.filter((category: { attributes: { name: string } }) => { return category.attributes?.name && category.attributes.name.includes(this.state.gender) && this.state.gender !== ""; })
      addFilter("min_price", filters.minPrice);
      addFilter("max_price", filters.maxPrice);
      addFilter("catalogue_variant_size_ids[]", filters.sizes?.join(","));
      addFilter("catalogue_variant_color_ids[]", filters.colors?.join(","));
      addFilter("sort", filters.sort);
      addFilter("category_ids[]", categoryID[0]?.id);  
      addFilter("catalogue_variant_size_ids[]", this.state.dummySizesPush.join(","));
      addFilter("catalogue_variant_color_ids[]", this.state.colorePush.join(","));
      addFilter("store_ids[]", this.state.storeCheck.join(","));
            
      return query;
    };

  getSearchApiCallID = async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
      token: token,
    };
    this.setState({
      circularProgress: true,
      paginateScroll: true
    })
    
      const updateUrl = this.state.token ? `${configJSON.getAdvancedSearchApiEndPointToken}`: `${configJSON.getSearchApiEndPoint}` ;
      const changesUrl = updateUrl + `?search=${this.state.search}&page=${this.state.page}&per_page=${this.state.perPage}${this.getFilterUri (this.state.filters)}&currency=${this.state.currencySign === "$" ? "dollar" : "dinar"}`
    const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getSearchdataApiCallID = requestMessage.messageId;
        const {lat, lng} = this.state.locationP || {}
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage), addLatLngToUrl(changesUrl,lat, lng)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  navigateTo = async ({
    id,
    props,
    screenName,
    raiseMessage,
}: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
}

  handleStorageChangeP = async() => {
    const newLocationP = await getStorageData("userLocation", true);
      if (newLocationP !== this.state.locationP) {
        this.setState({ locationP: newLocationP}, ()=>{
          this.handleClearAll(this.state.gender); 
          this.getBuyerStore();
        });
      }
  }
  // Customizable Area End
}
