import React from "react";

// Customizable Area Start

import { createTheme, styled } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    Hidden,
    TextField,
    Typography,
} from "@material-ui/core";import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {sideImage,facebookBlue,appleBlue,googleBlue,msgBlue,flag} from "../src/assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import CountrySelect from "../../../components/src/CountryDropDown.web"
// Customizable Area End

import MobileAccountLoginController, {
    CountrySuccess,
    Props, configJSON
} from "./MobileAccountLoginController.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class MobileAccountLoginBlock extends MobileAccountLoginController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const phoneClassName=this.state.mobileError?"inputFeild2Error2":"inputFeild2";
        const phoneErrorMessage=this.state.errorMsg.mobileNumberError ? this.state.errorMsg.errorMsgMobile : "";
        return (
            // Required for all blocks
            <>
                <Header navigation={this.props.navigation}/>
                <MainGridContainer className="LoginFormMainGrid" container>
                    <Hidden smDown>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
                        <Box className="sideImageBox">
                            <img src={sideImage} alt="" className="sideImageContaine" />
                        </Box>
                    </Grid>
                    </Hidden>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
                        <Box className={"formBox"}>
                            <form data-test-id="formSubmit1" onSubmit={this.handleSubmit} style={{ width: "100%" }}>
                                <Box>
                                    <Typography className={"welcomeText"}>{configJSON.welcomeBack}</Typography>
                                    <Typography className={"welcomeSubText"}>
                                        {configJSON.loginStyle}
                                    </Typography>

                                    <Typography data-test-id="countryId" className={"inputHeading"}>{configJSON.phoneNumber}</Typography>
                                    <Box className={"phoneInput"}>
                                    <CustomDropDownBox>
                                        <CountrySelect
                                            id="phoneCode"
                                            name="phoneCode"
                                            value={this.state.selectedCountry?.country_code || "KW"}
                                            placeholder="Select Phone Code"
                                            onChange={this.handleMobileChange}
                                            countryCode={this.state.countrySuccess}
                                            isBorder
                                        />
                                        </CustomDropDownBox>
                                        <TextField
                                            data-test-id="phoneNumberTextInput1"
                                            variant="outlined"
                                            className={phoneClassName}
                                            value={this.state.mobileNumber}
                                            onChange={this.handlePhoneNumberChange}
                                            placeholder={configJSON.phonePlace}
                                            error={this.state.errorMsg.mobileNumberError}
                                            helperText={phoneErrorMessage}
                                            inputProps={{ maxLength: 12 }}
                                            InputProps={{
                                                style: {
                                                    gap: "4px",
                                                    border: "0px",
                                                    borderRadius: "0px",
                                                    width: "420px"
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Button
                                    variant="contained"
                                    className={"loginUserButton"}
                                    disableElevation
                                    type="submit"
                                >
                                    {configJSON.sendOTP}
                                </Button>
                                <Snackbar
                                    open={this.state.isAlert}
                                    autoHideDuration={3000}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    onClose={this.onCloseAlert}
                                    data-test-id="alertTestId"
                                >
                                    <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                                </Snackbar>
                            </form>
                            <Typography className={"continueText"} align="center">
                                {configJSON.continueWith}
                            </Typography>
                            <Box className="socialLogin">
                            {(this.state.userType == "1" || this.state.userType === "seller" || this.state.userType == "3" || this.state.userType === "stylist") ? (
                                  <Button
                                  variant="contained"
                                  className={"loginUserButton1"}
                                  disableElevation
                                  data-test-id="mobileNavigation" onClick={this.openEmailScreen}
                                >
                                  <Box style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}} >
                                  <img src={msgBlue} alt="call"  style={{width: "24px", height: "24px"}} />
                                 <Typography style={{color: "#375280", fontFamily: "Lato", fontSize: "16px", fontWeight: 600}}>Log In with Email</Typography>
                                  </Box>
                                </Button>
                                ): (
                                  <>
                                <img src={msgBlue} alt="msg" data-test-id="emailtestID" onClick={this.openEmailScreen} className="socialLoginImage" />
                                <img src={facebookBlue} alt="Facebook" className="socialLoginImage" />
                                <img src={appleBlue} alt="Apple" className="socialLoginImage" />
                                <img src={googleBlue} alt="Google" className="socialLoginImage" />
                                </>
                                )}
                            </Box>
                            <Box className={"Register"}>
                                <Typography className={"bottomText"} align="center">
                                    {configJSON.msgAlert}
                                </Typography>
                                <Typography data-test-id="userNextMobile" className={"registerButton"} onClick={this.openRegistrationScreenMobile} >{configJSON.registerHere}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </MainGridContainer>
                <Footer navigation={this.props.navigation}/>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const MainGridContainer = styled(Grid)({
    display: "flex",
    justifyContent: "center",
    "& .sideImageBox": {
        width: "100%",
        height: "100%",
    },
    "& .sideImageContaine": {
        width: "100%",
        height: "100%",
    },
    "& .phoneInput": { display: "flex",gap:"15px", width: "100%" ,height: "60px"},
    "& .formGrid": {
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
    },
    "& .imageGrid": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    "& .welcomeSubText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "50px",
        alignSelf: "flex-start",
    },
    "& .inputHeading": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "20px 10px 10px 0px",
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    "& .formBox": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "50px",
        padding: "5px",
    },
    "& .loginUserButton": {
        textTransform:"capitalize",
        margin: "25px 0px 20px 0px !important",
        width: "100% !important",
        borderRadius: "0px !important",
        color: "white !important",
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
        gap: "8px",
        height: "56px !important",
        "& .MuiButton-label":{
            fontWeight:600,
            fontSize:"16px"
        }
    },
    "& .terms": {
        color: "#979797 !important",
        margin: "10px 10px 10px 10px !important",
        width: "520px !important",

        fontFamily: "Lato ,sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
    },
    "& .welcomeText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir,sans-serif",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
        alignSelf: "flex-start",
    },
    "& .Register": {
        padding: "10px",
        width: "520px !important",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .socialLogin": {
        padding: "20px",
        width: "80% !important",
        display: "flex",
        alignItems: "center",

        justifyContent: "center",
    },
    "& .socialLoginImage": {
        margin: "10px 25px",
    },
    "& .condition": {
        color: "var(--Neutrals-Cool-gray-500, #375280)",
        cursor: "pointer",
    },
    "& .listData":{
        color:'#375280',
        display: "flex",
        alignItems: "center",
        gap: "3px"
    },
    "& .bottomText": {
        color: "#375280 !important",

        fontFamily: "Lato ,sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
    },
    "& .registerButton": {
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        margin: "10px 10px 0 10px",

        fontFamily: "Lato ,sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        lineHeight: "24px !important",
    },
    "& .inputFeild2Error2":{
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            border:"none"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important"
        },
    },
    "& .inputFeild2": {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important"
        },
           "& .MuiOutlinedInput-notchedOutline":{
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root:hover":{
            border:"none, !important"
        }
    },
    "& .forgotPassword": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "10px",
        textOverflow: "ellipsis",
        cursor: "pointer",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "26px",
    },
    "& .listView":{
        height:"400px"
    },
    "& .continueText": {
        width: "100%",
        color: "#375280 !important",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
        display: "flex",
        flexDirection: "row",
        "&:before, &:after": {
          content: "''",
          flex: "1 1",
          borderBottom: "1px solid",
          margin: "auto"
        },
        "&:before": {
          marginRight: "25px"
        },
        "&:after": {
          marginLeft: "25px"
        }
      },
      "@media screen and (min-width: 0px) and (max-width: 1100px)": {
        "& .formBox": { width: "400px !important", flexWrap: "wrap" },
        "& .Register": {
          width: "350px !important",
        },
        "& .socialLogin": {
          width: "350px !important",
        },
        "& .terms": {
          width: "350px !important",
        },
        "& .loginUserButton": {
          width: "100%",
        },
        "& .phoneInput": { width: "100%" ,height: "60px"},
        "& .inputFeildPassword": {
          "& .MuiOutlinedInput-adornedEnd": {
              backgroundColor: "#F0F0F0",
              borderRadius: "2px",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
          },
      },
      },
      "& .loginUserButton1": {
        textTransform:"capitalize",
        margin: "25px 0px 20px 0px !important",
        width: "100% !important",
        borderRadius: "0px !important",
        color: "white !important",
        backgroundColor: "#FFFFFF !important",
        gap: "8px",
        border: "1px solid #CCBEB1",
        height: "56px !important",
        "& .MuiButton-label":{
            fontWeight:600,
            fontSize:"16px"
        }
    },
});

const CustomDropDownBox = styled(Box)({
    position: "relative",
    cursor: "pointer",
    width: "125px"
});
// Customizable Area End
